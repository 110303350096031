import { FontIcon, IColumn, IDropdownOption, TooltipHost } from "@fluentui/react";
import { IField } from "../../../dynamic-form/DynamicForm";
import { AclAccessesOptions, requiredMessage } from "../../common/common";
import { StringMap } from "../../common/types";
import { isSortable } from "../../common/utils";
import { IAclAccess } from "./types";

export const getGeneralFields = (options: StringMap): IField[] => {
   
    const fields: IField[] = [
        {
            label: "name",
            key: "name",
            type: "string",
            extras: { required: true },
            placeholder: "name",
            className: 'ms-Grid-col ms-lg4'
        },
        {
            label: "Type",
            key: "type",
            type: "dropdown",
            extras: { required: true },
            options: AclAccessesOptions.filter(d => d.key !== "all") as IDropdownOption[],
            className: 'ms-Grid-col ms-lg3'
        }
    ];
    if (options?.aclAccess?._id) {
        fields.push({
            label: "Is Deleted",
            key: "isDeleted",
            type: "checkbox",
            className: 'ms-Grid-col ms-lg3 margin-top-lg',
            extras: { disabled: true }
        })
    }
    fields.push({
        label: "Description",
        key: "description",
        type: "string",
        extras: { multiline: true, rows: 3 },
        placeholder: "Description",
        className: 'ms-Grid-col ms-lg12'
    })
    return fields;
}
export const validateAclAccessFunction = async (params: IAclAccess) => {
    let errors: StringMap = {};
    const { name, type } = params || {}
    if (!name) errors['name'] = requiredMessage;
    if (!type) errors['type'] = requiredMessage;
    // if (!country) errors['country'] = requiredMessage;
    // if (!Object.keys(errors).length) {
    //     errors = await validateUserFunctionApi(params) as unknown as StringMap
    // }
    return errors;
}

export const initProduct = {
    productId: '',
    title: '',
    description: '',
    region: 'GB',
    currency: 'GBP',
    revenueFix: 0,
    revenueVar: 0,
    recurring: false,
    scalingType: 'SystemSize'
}
export const getAclAccessColumns = (params: {
    actions: {
        icon: string,
        onClick: (task: any) => any
    }[]
}): IColumn[] => {
    return [
        {
            key: 'name',
            name: 'Name',
            fieldName: 'name',
            minWidth: 120,
            maxWidth: 150,
            ...isSortable,
            onRender: (item) => <TooltipHost content={item?.name} closeDelay={500}>
                 {item?.name}
                 </TooltipHost>,
        },
        {
            name: 'Description',
            key: 'description',
            fieldName: 'description',
            minWidth: 200,
            maxWidth: 600,
            ...isSortable,
            onRender: (item: any) => <TooltipHost content={item.description || ''} closeDelay={500}> {item?.description}</TooltipHost>
        },
        {
            name: 'Type',
            key: 'type',
            fieldName: 'type',
            minWidth: 75,
            maxWidth: 75,
            ...isSortable,
            onRender: (item: any) => <TooltipHost content={item.type || ''} closeDelay={500}> {item.type}</TooltipHost>
        },
        {
            key: 'active',
            name: 'Active',
            fieldName: 'isDeleted',
            minWidth: 75,
            maxWidth: 75,
            ...isSortable,
            onRender: (item: any) => <TooltipHost content={item.isDeleted ? 'No' : 'Yes'} closeDelay={500}>  {item.isDeleted ? 'No' : 'Yes'}</TooltipHost>
        },
        {
            key: 'action',
            name: 'Action',
            fieldName: '-',
            ariaLabel: "Action",
            minWidth: 150,
            maxWidth: 300,
            onRender: (taskType) => {
                return (
                    <div className="action-icons-container">
                        {params.actions.map((action: { icon: string | undefined; onClick: (arg0: any) => void; }) => {
                            return <FontIcon
                                iconName={action.icon}
                                onClick={() => action.onClick(taskType)} />;
                        })}
                    </div>
                );
            },
        }
    ];
}

export const initAclAccess: IAclAccess = {
    name: '',
    type: 'feature',
    isDeleted: false,
} as unknown as IAclAccess
