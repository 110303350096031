import { Action } from "./actionTypes";
import { IUserFunction } from "@solarforschools/sfs-core/dist/settings/types";
import { IUserFunctionFilter } from "../../../components/admin/userFunction/types";
import { IAclAccessFilter } from "../../../components/admin/solarportal/aclAccesses/types";

// States' definition
export interface IAclAccessState {
  isLoading: boolean;
  aclAccesses: any[];
  aclAccessFilter: IAclAccessFilter;
  totalCount: number;
}
export interface State {
  aclAccessState: IAclAccessState;
}

const aclAccess = (
  state: IAclAccessState = {
    isLoading: false,
    aclAccesses: [],
    aclAccessFilter: {
      page: 1,
      pageSize: 50,
      sortField: "Start",
      sortOrder: "desc",
    },
    totalCount: 0,
  },
  action: Action
): IAclAccessState => {
  switch (action.type) {
    case "SET_ACL_ACCESS_FILTER":
      return { ...state, aclAccessFilter: action.aclAccessFilter };
    case "SET_ACL_ACCESS_LIST":
      const aclAccesses = action.reset
        ? action.aclAccesses
        : [...state.aclAccesses, ...action.aclAccesses];
      return { ...state, aclAccesses, totalCount: action.totalCount };
    case "DELETE_ACL_ACCESS": {
      const stateObj = { ...state };
      const aclAccesses: IUserFunction[] = stateObj.aclAccesses.filter(
        (s) => s._id !== action._id
      );
      const aclAccessFilter = { ...stateObj.aclAccessFilter };
      const { page, pageSize } = aclAccessFilter;
      return {
        ...state,
        aclAccesses,
        totalCount: stateObj.totalCount,
        aclAccessFilter: {
          ...aclAccessFilter,
          page: Math.ceil((aclAccesses.length / page!) * pageSize!),
        },
      };
    }
    case "UPDATE_ACL_ACCESS": {
      const aclAccesses = [...state.aclAccesses];
      const index = aclAccesses.findIndex(
        (s) => s._id === action.aclAccess?._id
      );
      if (index > -1) {
        aclAccesses[index] = action.aclAccess;
      } else {
        aclAccesses.unshift(action.aclAccess);
      }
      return {
        ...state,
        aclAccesses,
        totalCount: index > -1 ? state.totalCount : state.totalCount + 1,
      };
    }
    default:
      return state;
  }
};

export default aclAccess;
