import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../index";
import { isLoading } from "../action";
import { toast } from "react-toastify";
import { IAclPermission, IAclPermissionFilter } from "../../../components/admin/solarportal/aclPermissions/types";
import { createAclPermissionApi, deleteAclPermissionApi, getAclPermissionsApi, updateAclPermissionApi } from "../../client/solarportal/aclPermission";
import { getSolarPortalApiHeader } from "../../client/solarportal/helper";

// Action Creators

export const setAclPermissionFilter = (aclPermissionFilter: IAclPermissionFilter) => {
  return { type: "SET_ACL_PERMISSION_FILTER", aclPermissionFilter };
};

export const setAclPermissionsList = (
  aclPermissions: IAclPermission[],
  totalCount: number,
  reset: boolean = true
) => {
  return { type: "SET_ACL_PERMISSION_LIST", aclPermissions, totalCount, reset };
};

export const updateAclPermissionList = (aclPermission: IAclPermission) => {
  return { type: "UPDATE_ACL_PERMISSION", aclPermission };
};

export const delAclPermission = (slug: string) => {
  return { type: "DELETE_ACL_PERMISSION", slug };
};

export const getAclPermissions =
  (params?: IAclPermissionFilter, reset: boolean = true) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        let reqBody = params;
        if (!reqBody) {
          const filters: IAclPermissionFilter =
            getState().web.aclPermission.aclPermissionFilter;
          reqBody = { ...filters };
        }
        const headers = getSolarPortalApiHeader()
        const { data: aclPermissions, totalCount } = await getAclPermissionsApi(reqBody, headers);
        dispatch(setAclPermissionsList(aclPermissions, totalCount, reset));
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };

export const createUpdateAclPermission =
  (params: IAclPermission) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        const headers = getSolarPortalApiHeader()
        const { data: aclPermission, message: msg } = params._id
          ? await updateAclPermissionApi(params, headers)
          : await createAclPermissionApi(params, headers);
        dispatch(updateAclPermissionList(aclPermission));
        toast.success(msg);
        dispatch(isLoading(false));
      } catch (e: any) {
        const msg = e?.response?.data?.message?.includes("is linked with roles") ? "Permission is linked with roles, delete that roles" : "Something went wrong"
        console.log(e);
        toast.error(msg);
        dispatch(isLoading(false));
      }
    };

export const deleteAclPermission =
  (aclPermission: IAclPermission) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        const headers = getSolarPortalApiHeader()
        const { message: msg } = await deleteAclPermissionApi(aclPermission, headers);
        dispatch(delAclPermission(aclPermission._id.toString()));
        toast.success(msg);
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };
