import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import Loader from '../common/Loader';
import { faFileExcel, faPlus, faVoicemail } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RootState } from '../../../store';
import { OpenGeneratePriceModal } from './GeneartePrice';
import { toast } from 'react-toastify';
import { isLoading } from '../../../store/admin/action';
import { saveInvoiceEmailAsDraft } from '../../../store/client/invoice';
import { OpenEmailPreviewModal } from '../common/EmailPreviewDialog';
import { ActionButton, Dropdown, IDropdownOption } from '@fluentui/react';
import { PageSize } from '../common/common';
import InvoicePriceFilters from './InvoicePriceFilter';
import { exportBillingInvoicePrices, fetchInvoicePrices, setInvoicePriceFilter } from '../../../store/admin/invoicePrice/action';
import { IInvoicePrice } from '../../../store/admin/invoicePrice/reducer';
import InvoicePriceList from './InvoicePriceList';
import { getInvoicePriceEmailPreview, getInvoicePricesAPI, saveInvoicePriceEmailAsDraft } from '../../../store/client/invoicePrice';

const Index = () => {

  const dispatch = useDispatch()
  const { invoicePrices, totalCount, invoicePriceFilter }: IInvoicePrice = useSelector<RootState, IInvoicePrice>((state: RootState) => state.web.invoicePrice)
  const [selectedItems, setSelectedItems] = useState<Array<any> | []>([])
  useEffect(() => {
    dispatch(fetchInvoicePrices())
  }, []);

  const openEmailPreview = async (param: any, d?: any[]) => {
    try {
      dispatch(isLoading(true))
      const { data = [] } = d?.length ? { data: d } : await getInvoicePricesAPI({ ...param, isGenerated: true, checkAuditLog: true, pageSize: totalCount, page: 1 })
      if (!data.length) {
        dispatch(isLoading(false))
        return
      }
      const { html }: any = await getInvoicePriceEmailPreview({ id: data[0]._id })
      dispatch(isLoading(false))
      await OpenEmailPreviewModal({
        params: {
          data: [...data],
          title: 'Invoice Price Email Preview',
          btnTitle: 'Save Draft',
          html,
          sendAction: saveInvoicePriceEmailAsDraft
        }
      })
    } catch (error: any) {
      console.log(error)
      toast.error(error?.response?.data?.msg || 'Something went wrong')
      dispatch(isLoading(false))
    }
  }
  const handleOnExport = () => {
    dispatch(exportBillingInvoicePrices())
  }

  const handleOnChangePageSize = (e: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined) => {
    const size: number = (item?.key && parseInt(item?.key.toString())) || 50
    const filters = { ...invoicePriceFilter, page: 1, pageSize: size }
    dispatch(setInvoicePriceFilter(filters))
    dispatch(fetchInvoicePrices())
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
      }}
    >
      <Loader />
      <header className="table-header">
        <h1>Billing Price</h1>
        <InvoicePriceFilters />
      </header>
      <div
        style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem" }}
        className="margin-top-md"
      >
        <div style={{ flex: "20%" }}>
          <span
            style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem", alignItems: "center" }}
          >
            {totalCount > 0 &&
              <>
                Displaying&nbsp;
                <Dropdown
                  selectedKey={invoicePriceFilter.pageSize?.toString() || "50"}
                  options={PageSize}
                  styles={{ dropdown: { width: "80px" } }}
                  onChange={handleOnChangePageSize}
                />
                &nbsp;/ {totalCount}
              </>}
          </span>
        </div>
        {((invoicePriceFilter.year?.length && invoicePriceFilter.funder !== 'all') || selectedItems?.length > 0) && (
          <ActionButton
            allowDisabledFocus
            style={{ textAlign: 'right' }}
            title='Save as Draft'
            onClick={async () => {
              await openEmailPreview(invoicePriceFilter, selectedItems)
            }}
          ><FontAwesomeIcon icon={faVoicemail} size='1x' color='#106ebe' />  &nbsp;Send Invoice Price
          </ActionButton>
        )}
        <ActionButton
          allowDisabledFocus
          style={{ textAlign: 'right' }}
          onClick={() => handleOnExport()}
        ><FontAwesomeIcon icon={faFileExcel} size='1x' color="#106ebe" />  &nbsp;Export
        </ActionButton>
        <ActionButton
          allowDisabledFocus
          style={{ textAlign: 'right' }}
          onClick={async () =>
            await OpenGeneratePriceModal()
          }
        ><FontAwesomeIcon icon={faPlus} size='1x' color='#106ebe' />  &nbsp;Generate Price
        </ActionButton>
      </div>
      {invoicePrices.length && <InvoicePriceList setSelectedInvoices={(data: IInvoicePrice[]) => setSelectedItems(data)} />}
    </div>
  )
}
export default Index;
