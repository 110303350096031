import { ComboBox, TextField } from '@fluentui/react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { getUserFunctions, setUserFunctionFilter } from '../../../../store/admin/userFunction/action';
import { IUserFunctionState } from '../../../../store/admin/userFunction/reducer';
import { AclPermissionTypes, Regions } from '../../common/common';
import { statusOptions } from '../../user/common';
import aclPermission, { IAclPermissionState } from '../../../../store/admin/aclPermission/reducer';
import { getAclPermissions, setAclPermissionFilter } from '../../../../store/admin/aclPermission/action';

const AclPermissionFilters = (props: AclPermissionFilterProps) => {
       const dispatch = useDispatch()
        const { aclPermissionFilter }: IAclPermissionState = useSelector<RootState, IAclPermissionState>((state) => state.web.aclPermission);
        const handleOnChangeInput = (
            value?: any,
            key?: string
        ) => {
            const params = { ...aclPermissionFilter, page: 1, [key!]: value };
            dispatch(setAclPermissionFilter(params));
            switch (key) {
                case 'type':
                    if (value === "all") {
                        delete params.type;
                    }
                    dispatch(getAclPermissions(params));
                    break;
                default:
                    if (value?.length === 0 || (value && value.length > 1)) {
                        dispatch(getAclPermissions(params));
                    }
                    break;
            }
        }
    
        const { name, type } = aclPermissionFilter
        return (
            <div
                className="ms-Grid"
                dir="ltr"
                style={{ minWidth: "700px", maxWidth: "1300px" }}
            >
                <div className="ms-Grid-row">
                    <TextField
                        label="Name"
                        placeholder="Enter Name"
                        type="text"
                        onChange={(e, value) => handleOnChangeInput(value, "name",)}
                        value={name!}
                        className="ms-Grid-col ms-lg6"
                    />
    
                    <ComboBox
                        label="Type"
                        selectedKey={type ? type : "all"}
                        options={AclPermissionTypes}
                        autoComplete={"on"}
                        onChange={(e, item) => handleOnChangeInput(item?.key, 'type')}
                        className="ms-Grid-col ms-lg3"
                    />
                </div>
            </div>
        );
    }

interface AclPermissionFilterProps {

}

export default AclPermissionFilters;

