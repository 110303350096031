
import { CSSProperties, useEffect, useMemo, useState } from "react";
import moment from "moment";
import {
  ActionButton,
  CheckboxVisibility,
  DetailsList,
  Text,
  FontIcon,
  Link,
  ScrollablePane,
  ScrollbarVisibility,
  Shimmer,
  TooltipHost,
  Toggle,
  IDropdownOption,
  Dropdown,
} from "@fluentui/react";
import { SelectionMode, IColumn, Selection } from "@fluentui/react/lib/index";
import { IEstablishment, IEstablishment as IEstablishmentDocument } from '@solarforschools/sfs-core/dist/school/types';
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTasks, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { RootState } from "../../../store";
import { isSortable, SetDetailListHeaderSortDirection } from "../common/utils";
import { ConfirmDialog } from "../common/ConfirmDialog";
import { DetailListStickyHeader } from "../common/DetailListStickyHeader";
import { ISchool } from "../../../store/admin/school/reducer";
import { deleteSchool, fetchSchools, setSchoolsFilter, exportSchools, createUpdateSchool, saveThisConfig } from "../../../store/admin/school/action";
import { ISchoolsFilter } from "./types";
import { DropdownParams, StringMap } from "../common/types";
import ModalDialog from "../common/ModalDialog";
import { EditStage } from "../common/EditStage";
import { OpenOpportunityModal } from "../opportunity/AddOpportunity";
import { initOpportunity } from "../opportunity/helper";
import { fetchSystems } from "../../../store/admin/system/action";
import EditSchoolDialog, { openEditSchoolModal } from "./EditSchoolDialog";
import { awsUrlHost, websiteHost } from "../../../store/config";
import { useParams } from "react-router-dom";
import { getSchoolApi } from "../../../store/client/school";
import { getOpportunityApi } from "../../../store/client/opportunity";
import { initSchool } from "./helper";
import openAssignSchoolModal from "./AssignSchoolDialog";
import InView from "react-intersection-observer";
import { RenderListRow } from "../common/RenderListRow";
import MapViewSchools from "./MapViewSchools";
import { IAdmin } from "../../../store/admin/reducer";
import { PageSize } from "../common/common";
import Pagination from "../common/Pagination";
import { updateEstablishmentServices } from "../../../store/client/solarportal/schools";
import { isEqual } from "lodash";

const SchoolList = ({ actions }: Props = { actions: [] }) => {
  const dispatch = useDispatch();
  const params: StringMap = useParams();
  const { schools: establishments, users, totalCount, schoolsFilter, projects }: ISchool = useSelector<RootState, ISchool>((state: RootState) => state.web.school);
  const [actionModalStatus, setActionModalStatus] = useState<{ [key: string]: boolean }>(actions.reduce((a, v) => ({ ...a, [v]: false }), {}));
  const [selectedSchool] = useState<IEstablishmentDocument>();
  const admin: IAdmin = useSelector<RootState, IAdmin>(
    (state: RootState) => state.web.admin
  );
  const [hasDeletePermission] = useState(admin?.user?.permission?.includes("delete"))

  const headerRow: IColumn[] = [
    {
      key: "meta.logo",
      name: "Logo",
      fieldName: "meta.logo",
      minWidth: 40,
      maxWidth: 40,
      data: "string",
      onRender: (item) => {
        return (
          item.meta?.logo && (
            <a href={`${awsUrlHost}/schools/${item.slug}/live/${item.meta?.logo}`} target="_blank" style={{ width: "30px" }}>
              <img
                height={30}
                width={30}
                src={`${awsUrlHost}/schools/${item.slug}/live/${item.meta?.logo}`}
                alt={"NoImage"}
              />
            </a>
          )
        );
      },
    },
    {
      key: "name",
      name: "Name",
      fieldName: "name",
      minWidth: 130,
      data: "string",
      ...isSortable,
      onRender: (item) => {
        return (
          <TooltipHost content={item.name} closeDelay={500}>
            <Link rel='noopener noreferrer' target='_blank' href={`${websiteHost}/schools/${item.slug}`}>
              {item.name}
            </Link>
          </TooltipHost>
        );
      },
    },
    {
      key: "planDate",
      name: "Plan Date",
      fieldName: "planDate",
      minWidth: 80,
      maxWidth: 80,
      data: "string",
      ...isSortable,
      onRender: (item) => item.planDate ? moment(item.planDate).format("YYYY/MM/DD") : '',
    },
    {
      key: "meta.status",
      name: "Status",
      fieldName: "meta.status",
      minWidth: 80,
      data: "string",
      ...isSortable,
      onRender: (item: IEstablishment) => item.meta.status
    },

    {
      key: "activeStage.name",
      name: "Stage",
      fieldName: "activeStage.name",
      minWidth: 50,
      data: "string",
      ...isSortable,
      onRender: (item: IEstablishment) => {
        return (
          <TooltipHost content={item.activeStage?.name} closeDelay={500}>
            <span style={{ color: item.activeStage?.active ? 'green' : 'red' }}>{item.activeStage?.name}</span>
          </TooltipHost>
        );
      },
    },
    {
      key: "address.city",
      name: "City",
      fieldName: "address.city",
      minWidth: 70,
      data: "string",
      ...isSortable,
      onRender: (item) => {
        return (
          <TooltipHost content={item.address?.city} closeDelay={500}>
            {item.address?.city}
          </TooltipHost>
        );
      },
    },
    {
      key: "address.zipcode",
      name: "Zip Code",
      fieldName: "address.zipcode",
      minWidth: 70,
      ...isSortable,
      onRender: (item) => item.address?.zipcode
    },
    {
      key: "address.street",
      name: "Street",
      fieldName: "address.street",
      minWidth: 125,
      ...isSortable,
      onRender: (item) => {
        return (
          <TooltipHost content={item.address?.street} closeDelay={500}>
            {item.address?.street}
          </TooltipHost>
        );
      },
    },
    {
      key: "students",
      name: "Students",
      fieldName: "students",
      minWidth: 90,
      data: "number",
      className: "text-right",
      ...isSortable,
    },
    {
      key: "rank",
      name: "Priority",
      fieldName: "rank",
      minWidth: 60,
      ...isSortable,
    },
    {
      key: "action",
      name: "Action",
      fieldName: "-",
      minWidth: 100,
      onRender: (school: IEstablishmentDocument) => {

        const deleteIconStyle: CSSProperties = {
          opacity: "0.5",
          pointerEvents: "none"
        }

        return (
          <span>
            {actions.includes('opportunity') && (
              <FontIcon className="action-icons" iconName="Money" title="Add/Edit Opportunity" onClick={() => handleOnAddOpportunity(school)} />
            )}
            {actions.includes('edit') && (
              <FontIcon className="action-icons" iconName="Edit" title="Edit School" onClick={() => handleEditSchool(school)} />
            )}
            <FontIcon className="action-icons" style={hasDeletePermission ? {} : { ...deleteIconStyle }} iconName="Trash" title="Delete School" onClick={() => handleOnDelete(school)} />
          </span>
        );
      },
    },
  ];
  const [columns, setColumns] = useState<IColumn[]>(headerRow);
  // const selection = new Selection({
  //   onSelectionChanged: () => {
  //     const data = selection.getSelection()
  //     setSelectedList(data.filter(Boolean))
  //   }
  // })
  const [selectedItems, setSelectedItems] = useState<Array<any> | undefined>(undefined)
  const [selection] = useState(new Selection({
    onSelectionChanged: () => {
      const data = selection.getSelection()
      setSelectedItems(data.filter(Boolean));
    }
  }));
  const [isToggle, setIsToggle] = useState<boolean | undefined>(false)
  const setModalStatus = (type: string, status: boolean) => {
    const modalStatus = actions.reduce((a, v) => ({ ...a, [v]: v === type ? status : false }), {})
    setActionModalStatus({ ...modalStatus })
    if (type === 'edit' && status === false) window.history.replaceState(null, "", `/schools`)
  }

  const loadData = async () => {
    if (params.slug) {
      const { school }: any = await getSchoolApi(params.slug)
      if (school) {
        handleEditSchool(school)
      }
    }
  }

  useEffect(() => {
    const preSelected: any = (selectedItems);
    if (preSelected?.length > 0) {
      selection.setChangeEvents(false);
      preSelected.forEach((item: any) => {
        const index = establishments.findIndex((s: any) => s?._id.toString() === item?._id.toString())
        if (index >= 0) {
          selection.setIndexSelected(index, true, true)
        }
      });
      selection.setChangeEvents(true);
    }
  }, [establishments, selectedItems]);

  useEffect(() => {
    loadData();
  }, [])

  async function handleEditSchool(school: IEstablishment) {
    if (school._id) window.history.replaceState(null, "", `/schools/${school.slug}`)
    const result = await openEditSchoolModal({
      school,
      onFetchSystem: () => dispatch(fetchSystems("all", false)),
      onCancel: () => null,
      onSave: () => null,
    });
    if (result) {
      await dispatch(createUpdateSchool(result))

      const isSameServices2 = isEqual(
        school?.services2?.sort(),
        result?.services2?.sort()
      )
      if(!isSameServices2) {
        // async udpate -  Only update if school is live and non-live + services2 is not empty
        updateEstablishmentServices(result?.slug)
      }
    }
    if (school._id) window.history.replaceState(null, "", `/schools`)
  }

  // const handleEditSchool = (school: IEstablishmentDocument, action = "edit") => {
  //   if (action === 'edit') window.history.replaceState(null, "", `/schools/${school.slug}`)
  //   setSelectedSchool(prevState => ({ ...school }))
  //   setModalStatus(action, true);
  // };

  const handleOnSort = (item: DropdownParams) => {
    const params = { ...schoolsFilter, ...{ sortField: item?.key, sortOrder: item?.text } };
    dispatch(setSchoolsFilter(params as ISchoolsFilter));
    dispatch(fetchSchools(params));
  }

  const handleOnHeaderClick = (column?: IColumn): void => {
    if (column?.key === 'action') return
    const index = columns.findIndex(c => c.key === column?.key)
    const newColumns = SetDetailListHeaderSortDirection(column, columns)
    setColumns(newColumns)
    handleOnSort({ key: newColumns[index].key, text: newColumns[index].isSortedDescending ? 'desc' : 'asc' })
  };

  const handleOnDelete = async (school: IEstablishmentDocument) => {
    const confirm = await ConfirmDialog({
      dialogContentProps: {
        title: "Delete School",
        closeButtonAriaLabel: "Close",
        subText: `Are you want to delete`,
      },
    });
    if (confirm) dispatch(deleteSchool(school))
  }
  const handleOnDismissModal = () => {
    setModalStatus('edit', false)
    setModalStatus('add', false)
  };

  const handleOnSave = async (school: IEstablishmentDocument, action?: string) => {
    const data: any = await dispatch(createUpdateSchool(school))
    if (action) {
      setModalStatus(action, false)
    } else {
      setModalStatus('edit', false)
      setModalStatus('add', false)
    }
    if (!school._id) {
      handleEditSchool(data)
    }
  }

  const handleOnAddOpportunity = async (school: IEstablishmentDocument) => {
    let { opportunity, totals = {} }: any = await getOpportunityApi(school.slug, 'School')
    if (!opportunity) opportunity = initOpportunity(school, 'School', totals)
    await OpenOpportunityModal({ params: opportunity })
  }
  // const renderRowFields = (props: IDetailsRowFieldsProps) => {
  //   return (
  //     <span data-selection-disabled={true}>
  //       <DetailsRowFields {...props} />
  //     </span>
  //   );
  // }
  // const renderRow = (props: IDetailsRowProps) => {
  //   return <DetailsRow rowFieldsAs={renderRowFields} {...props} />;
  // }
  const handleOnExport = () => {
    dispatch(exportSchools({ ...schoolsFilter, all: true }))
  }
  const loadNextPage = () => {
    const { page = 0 } = schoolsFilter
    const params = { ...schoolsFilter, page: page + 1 }
    dispatch(setSchoolsFilter(params))
    dispatch(fetchSchools(params, false))
  }
  const onRenderMissingItem = (index?: number) => {
    if (establishments.length < totalCount) {
      return (
        <InView as="div" onChange={(inView) => inView && loadNextPage && loadNextPage()}>
          <Shimmer width='100%' />
        </InView>
      )
    }
    return <></>
  }

  const handleToggle = (e: React.MouseEvent<HTMLElement>, checked?: boolean) => {
    setIsToggle(checked)
  }
  const setFormElement = (filterParams: any) => {
    const params = { ...schoolsFilter, page: 1, ...filterParams };
    dispatch(setSchoolsFilter(params));
    dispatch(fetchSchools())
  };

  const handleOnChangePageSize = (e: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined) => {
    const size: number = (item?.key && parseInt(item?.key.toString())) || 10
    setFormElement({ pageSize: size });
  };

  const ToggleButton = useMemo(() => {
    return <>
      <Toggle onChange={handleToggle} className="mapViewToggle" />
      <div className="flex-row-center">
        <div>
          <Text variant="medium">
            Map View
          </Text>
        </div>
      </div>
    </>
  }, [])


  const handleRenderFilters = async () => {

    const isConfirmed = await ConfirmDialog({
      dialogContentProps: {
        title: "Save map for project stages",
        closeButtonAriaLabel: "Close",
        subText: `Are you sure want to save ?`,
      },
      confirmBtnText: "Save",
    });

    if (isConfirmed) {
      const tempSchoolsFilter = { ...schoolsFilter }
      delete tempSchoolsFilter["page"]
      delete tempSchoolsFilter["pageSize"]

      dispatch(saveThisConfig(tempSchoolsFilter))
    }
  }


  const RenderContents = () => {
    return <>
      <div
        style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem" }}
        className="margin-top-md"
      >

        <div style={isToggle ? { flex: "20%", visibility: "hidden" } : { flex: "20%" }}>
          <span
            style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem", alignItems: "center" }}
          >
            {totalCount > 0 &&
              <>
                Displaying&nbsp;
                <Dropdown
                  selectedKey={schoolsFilter.pageSize?.toString() || "10"}
                  options={PageSize}
                  styles={{ dropdown: { width: "80px" } }}
                  onChange={handleOnChangePageSize}
                />
                &nbsp;/ {totalCount}
              </>}
          </span>
        </div>
        <>
          <div style={isToggle ? { visibility: "hidden" } : {}}>
            {selectedItems && selectedItems?.length > 0 && (
              <>
                <ActionButton
                  allowDisabledFocus
                  style={{ textAlign: 'right' }}
                  onClick={async () => await openAssignSchoolModal({ schools: selectedItems, mode: 'assign' })}
                >
                  <FontAwesomeIcon icon={faUserCircle} size='1x' color="#106ebe" />  &nbsp;Assign
                </ActionButton>
                <ActionButton
                  allowDisabledFocus
                  style={{ textAlign: 'right' }}
                  onClick={async () => await openAssignSchoolModal({ schools: selectedItems, mode: 'unassign' })}
                >
                  <FontAwesomeIcon icon={faTasks} size='1x' color="#106ebe" />  &nbsp;Un Assign
                </ActionButton></>
            )
            }

            {schoolsFilter?.status === "live" && <ActionButton
              iconProps={{ iconName: 'Add' }}
              onClick={() => dispatch(exportSchools({ ...schoolsFilter, all: true, livereport: true }))}>
              Live Report Export
            </ActionButton>
            }

          </div>
          <div style={isToggle ? { visibility: "hidden" } : {}}>
            <ActionButton iconProps={{ iconName: 'Add' }} onClick={handleOnExport}>
              Export
            </ActionButton>
            <ActionButton iconProps={{ iconName: 'Add' }} onClick={() => handleEditSchool(initSchool)}>
              Add School
            </ActionButton>
          </div>

          <div className="flex-row-center" style={{ marginRight: "1rem" }}>
            {isToggle ?
              <ActionButton iconProps={{ iconName: 'Add' }} onClick={handleRenderFilters}>
                Save filter for bond holders
              </ActionButton>
              : null}
          </div>
          {ToggleButton}
        </>
      </div>
    </>
  }

  return (
    <>
      {RenderContents()}
      {
        !isToggle ? (
          <>
            {totalCount ? (
              <>
            
                  
                    <DetailsList
                      items={establishments}
                      columns={columns}
                      selectionMode={SelectionMode.multiple}
                      onColumnHeaderClick={(e, column) => handleOnHeaderClick(column)}
                      onRenderDetailsHeader={DetailListStickyHeader}
                      compact={true}
                      selection={selection}
                      checkboxVisibility={CheckboxVisibility.always}
                      onRenderRow={(props) => RenderListRow(props!)}
                      // onRenderMissingItem={onRenderMissingItem}
                    />
                
              
                <Pagination
                  totalRecords={totalCount}
                  onPaginate={(page) => setFormElement({ page })}
                  currentPage={schoolsFilter?.page!}
                  pageSize={schoolsFilter.pageSize!}
                />
              </>
            ) : (
              <p style={{ paddingLeft: "1rem", textAlign: "center" }}>
                No Data Found!
              </p>
            )}
            {selectedSchool && (
              <>
                <ModalDialog
                  isModalOpen={actionModalStatus?.edit || actionModalStatus?.add}
                  title={"Add Edit School"}
                  onDismiss={handleOnDismissModal}
                >
                  <EditSchoolDialog
                    school={selectedSchool}
                    onCancel={handleOnDismissModal}
                    onSave={handleOnSave}
                    onFetchSystem={() => dispatch(fetchSystems("all", false))}
                  />
                </ModalDialog>
                <ModalDialog
                  isModalOpen={actionModalStatus?.stage}
                  title={"Add Edit Stage"}
                  onDismiss={handleOnDismissModal}
                  containerClassName="modal-size-sm"
                >
                  <EditStage
                    school={selectedSchool}
                    onCancel={handleOnDismissModal}
                    onSave={handleOnSave}
                  />
                </ModalDialog>
              </>
            )}
          </>
        ) : <MapViewSchools schoolsDetails={establishments} />

      }
    </>
  );
};

export default SchoolList;
interface OwnProps {
  actions: string[];
}

type Props = OwnProps;
