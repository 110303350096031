import axios from "axios";
import Qs from "querystring";
import { host } from "../store/config";

export const generateQueryString = (
  params: Qs.ParsedUrlQueryInput | undefined
) => {
  return Qs.stringify(params);
};

export const isTokenAlive = async () => {
  const params = new URLSearchParams(window.location.search);
  const param1 = params.get("token");

  if (param1) {
    localStorage.setItem("token", param1);
  }

  if (localStorage.getItem("token")) {
    try {
      await axios.get(`${host}/users/isValidToken`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      localStorage.setItem("isTokenValid", "true");
    } catch (error) {
      localStorage.removeItem("isTokenValid");
      localStorage.removeItem("token");
      localStorage.removeItem("spToken");
      //   window.location.replace(
      //     `${window.location.origin}/login?redirectTo=${window.location.href}`
      //   );
      window.history.pushState(
        {},
        "",
        `${window.location.origin}/login?redirectTo=${window.location.href}`
      );
    }
  } else {
    let path = window.location.origin;
    if (window.location.pathname !== "/") {
      path = `${window.location.origin}/login?redirectTo=${window.location.href}`;
    }
    window.history.pushState({}, "", path);
  }
};

export const developers = [
  "vignesh@solarforschools.co",
  "usman@solarforschools.co",
  "rainer@solarforschools.co",
  "simonh@solarforschools.co.uk",
];

export const numberToCurrencyFormat = (number: number, region = "en-US") =>
  Intl.NumberFormat(region).format(number);
export const ONE_DRIVE_SDK = "https://js.live.net/v7.2/OneDrive.js";
export const SP_CLIENT_ID = "b02b466c-f3c8-4ea9-a890-2d34b3fdbe7b";

export const clearTokenParams = () => {
  const params = new URLSearchParams(window.location.search);
  const tokenParams = params.get("token");
  if (tokenParams) {
    let url = window.location.href;
    let cleanUrl = url.split("?")[0];
    window.location.href = cleanUrl;
  }
};
