import { useEffect } from 'react'
import { useDispatch } from "react-redux";

import Loader from '../../common/Loader';
import { getAclAccesses } from '../../../../store/admin/aclAccess/action';
import AclAccessFilters from './AclAccessFilters';
import AclAccessList from './AclAccessList';

const AclAccessScreen = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAclAccesses())
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
      }}
    >
      <Loader />
      <header className="table-header">
        <h1>ACL Accesses</h1>
        <AclAccessFilters />
      </header>
      <AclAccessList actions={['add', 'edit', 'delete']} />
    </div>
  );
}
export default AclAccessScreen;

