import { ComboBox, DefaultButton, PrimaryButton, Stack, Sticky, StickyPositionType, TextField } from "@fluentui/react"
import _ from "lodash"
import { useEffect, useMemo, useState } from "react"
import ReactDOM from "react-dom"
import { Provider, useDispatch } from "react-redux"
import store from "../../../../store"
import DynamicForm, { IField } from "../../../dynamic-form/DynamicForm"
import ModalDialog from "../../common/ModalDialog"
import { getGeneralFields, initRole, validateAclRole } from "./helper"
import { IAclRole } from "./types"
import { createUpdateAclRole } from "../../../../store/admin/aclRole/action"
import { getAclPermissionsApi } from "../../../../store/client/solarportal/aclPermission"
import { toast } from "react-toastify"
import { IComboBoxOption } from "office-ui-fabric-react"
import { getSolarPortalApiHeader } from "../../../../store/client/solarportal/helper"
import { AxiosHeaders } from "axios"


export const OpenAclRoleModal = (props?: any) => {
  return new Promise<IAclRole | undefined>((resolve, reject) => {
    const mountTarget = document.createElement('div')
    document.body.appendChild(mountTarget)
    const callback = (aclRole?: IAclRole) => {
      resolve(aclRole);
      ReactDOM.unmountComponentAtNode(mountTarget)
      mountTarget.remove()
    }
    ReactDOM.render(
      <Provider store={store}>
        <ModalDialog
          isModalOpen={true}
          title="Add/Edit Acl Role"
          onDismiss={() => callback(undefined)}
          containerClassName={"modal-size-sm"}
        >
          <EditAclRoleDialog
            {...props}
            onSave={(aclRole: IAclRole | undefined) => callback(aclRole)}
            onCancel={() => callback(undefined)}
          />
        </ModalDialog>
      </Provider>,
      mountTarget
    )
  })
}

const EditAclRoleDialog = (props: UserFunctionProps) => {
  const dispatch = useDispatch();

  const [aclRole, setAclRole] = useState<IAclRole>(_.cloneDeep(props.aclRole || initRole as IAclRole))
  const [errors, setErrors] = useState<any>({});
  const [permissions, setPermissions] = useState<any[]>([]);

  const fetchPermissions = async () => {
    try {
      const header = getSolarPortalApiHeader()
      const { data } = await getAclPermissionsApi({ sortBy: 'name', orderBy: "asc", all: true }, header as any)
      setPermissions(data.map((d: any) => ({ key: d._id, text: d.name, isDeleted: d?.isDeleted })));
    } catch (error) {
      console.log(error)
      toast.error("Failed to fetch permissions");
    }

  };
  useEffect(() => {
    fetchPermissions();
  }, []);

  const generalFields = useMemo(() => getGeneralFields({ aclRole }), [aclRole]);

  const handleOnSave = async () => {

    const errors = await validateAclRole(aclRole);
    const data = { ...aclRole }
    if (Object.keys(errors).length) {
      // const tabErrors = getTabErrors(errors);
      setErrors(errors);
      // setTabErrors(tabErrors);
      return;
    }
    if (data.created) delete data.created
    if (data.updated) delete data.updated
    await dispatch(createUpdateAclRole(data))
    props.onSave(aclRole)
  }


  const handleOnChangeInput = (key: string, value: any) => {
    if (key === "permissions") {

      const filteredObjects = permissions?.filter(obj => value?.includes(obj?.key));

      const deletedObjects = filteredObjects.filter(obj => obj.isDeleted);

      if (deletedObjects && deletedObjects.length) {
        const deletedPermission = deletedObjects.map(obj => obj.text).join(", ")
        toast.error(`${deletedPermission} invalid, as it is deleted`)
        return
      }
    }
    switch (key) {
      default:
        setAclRole(_.set({ ...aclRole }, key, value))
        break;
    }
  }
  return (
    <div className="edit-record">
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <DynamicForm
            fields={generalFields as IField[]}
            data={aclRole}
            onChange={handleOnChangeInput}
            errors={errors}
          />
          <ComboBox
            label="Select Permissions"
            multiSelect
            selectedKey={aclRole.permissions.map((key: any) => key.toString()) || []}
            placeholder="Type to filter"
            allowFreeform={true}
            autoComplete={"on"}
            options={permissions as IComboBoxOption[]}
            className="ms-Grid-col ms-lg12"
            errorMessage={errors.permissions}
            onChange={(e, item) => {
              const selectedKeys = item!?.selected
                ? [...aclRole?.permissions, item!?.key]
                : aclRole.permissions.filter((key: any) => key !== item!?.key);
              handleOnChangeInput("permissions", selectedKeys);
            }}
          />
        </div>
        <div className="ms-Grid-row">
          <TextField
            name="description"
            defaultValue={aclRole?.description}
            label="Description"
            multiline
            rows={3}
            autoAdjustHeight
            errorMessage={errors.description}
            onChange={(e, item) => handleOnChangeInput("description", item)}
          />
        </div>

      </div>
      <Sticky stickyPosition={StickyPositionType.Footer}>
        <Stack horizontal horizontalAlign="center">
          <Stack.Item>
            <PrimaryButton
              text="Save"
              onClick={handleOnSave}
              className="btn-primary"
            />
            <DefaultButton onClick={props.onCancel} text="Cancel" />
          </Stack.Item>
        </Stack>
      </Sticky>
    </div>
  );
};

interface UserFunctionProps {
  onCancel?: () => void;
  onSave: (aclRole: IAclRole) => void;
  aclRole?: IAclRole;
}

export default OpenAclRoleModal;
