import React, { useState, useEffect } from "react";
import Button from "../learning/Content/formElements/Button";
import Label from "../learning/Content/formElements/Label";
import Textbox from "../learning/Content/formElements/Textbox";
import "./loginStyle.css";
import { ThunkDispatch } from "redux-thunk";
import { connect, useDispatch } from "react-redux";
import { IAdmin } from "../../store/admin/reducer";
import {
  login,
  verifyUserStatus,
  registerAuthEvent,
  setRedirectURL,
  isLoading,
  setAuthToken,
  setUserDetails,
} from "../../store/admin/action";
import { IAuthEvent, ILoginCredentials } from "../../store/admin/actionTypes";
import { Redirect, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../admin/common/Loader";
import { set } from "lodash";
import store from "../../store";
import axios from "axios";
import { host, solaportaApiHost } from "../../store/config";
import bgImg1 from "../../images/bgImg1.png";
import bgImg2 from "../../images/bgImg2.png";
import bgImg3 from "../../images/bgImg3.png";

const LoginScreen = ({
  web,
  login,
  verifyUserStatus,
  registerAuthEvent,
  setRedirectURL,
}: Props) => {

  const backgrounds = [
    bgImg1, 
    bgImg2,
    bgImg3
  ]
  const location = useLocation();
  const dispatch = useDispatch();
  const redirectUrl = new URLSearchParams(location.search).get("redirectTo");
  if (web.redirectTo === "" && redirectUrl !== null) {
    setRedirectURL(redirectUrl!);
  }
  const [bgImg, setBgImg] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mfaCode, setMfaCode] = useState("");
  const [mfaRequired, setMfaRequired] = useState<boolean>(false);

  useEffect(()=>{
    const randomBg = backgrounds[Math.floor(Math.random() * backgrounds.length)];
    setBgImg(randomBg);
  },[])

  const emailRegularExpression =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  if (web.isAuthenticated) {
    return <Redirect to="/home" />;
  }
  if (!web.authEvent?.login) {
    window.addEventListener("storage", (e) => {
      if (e.key === "token" && e.newValue !== null) {
        setTimeout(() => {
          verifyUserStatus();
        }, 0);
      }
    });
    registerAuthEvent({ ...web.authEvent, login: true });
  }
  const handleSubmit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null
  ) => {
    e?.preventDefault();
    if (!email || !emailRegularExpression.test(email.toLowerCase())) {
      toast.error(`Enter valid email address`);
      return;
    }
    if (!password) {
      toast.error(`Enter valid password`);
      return;
    }
    if (mfaRequired && !mfaCode) {
      toast.error(`Enter valid MFA code`);
      return;
    }
    try {
      dispatch(isLoading(true));
      axios
        .post(`${host}/login`, { email, password, mfaCode })
        .then((res) => {
          dispatch(isLoading(false));
          if (res.status === 200) {
            if (res.data?.mfaRequired) {
              setMfaRequired(res.data.mfaRequired)
              return;
            }
            axios
              .post(`${solaportaApiHost}/v1/login`, { email, password, mfaCode })
              .then((res1) => {
                toast.success("Login Success !");
                dispatch(setAuthToken(res.data.access_token));
                localStorage.setItem("spToken", res1.data.data.access_token);
                dispatch(setUserDetails());
                const redirectURL = store.getState().web.admin.redirectTo;
                if (redirectURL) {
                  dispatch(setRedirectURL(""));
                  window.location.replace(redirectURL);
                }
              })
              .catch((err: { response: { status: number; data: { msg: {} | null | undefined; }; }; }) => {
                if (err?.response?.status === 401) {
                  toast.error(err.response.data.msg);
                } else if (err?.response?.status === 400) {
                  toast.error(err.response.data.msg);
                }
                setTimeout(() => {
                  dispatch(isLoading(false));
                }, 500);
              });
            // toast.success("Login Success !");
            // dispatch(setAuthToken(res.data.access_token));
            // dispatch(setUserDetails());
            // const redirectURL = store.getState().web.admin.redirectTo;
            // if (redirectURL) {
            //   dispatch(setRedirectURL(""));
            //   window.location.replace(redirectURL);
            // }
          }
        })
        .catch((err: { response: { status: number; data: { msg: {} | null | undefined; }; }; }) => {
          if (err?.response?.status === 401) {
            toast.error(err.response.data.msg);
          } else if (err?.response?.status === 400) {
            toast.error(err.response.data.msg);
          }
          setTimeout(() => {
            dispatch(isLoading(false));
          }, 500);
        });
    } catch (e) {
      console.log(e);
    }

    // login({
    //   email,
    //   password,
    // });
  };
  const loginTemplate = { background:"rgba(0, 0, 0, 0.3)", padding:"1rem 2rem", borderRadius:"10px" }
  return (
    <section id="main-container" style={{ backgroundImage: `url(${bgImg})`, backgroundSize: "cover" }}>
      {web.isLoading && <Loader />}

      <nav>
        {/* https://pixabay.com/photos/solar-cell-solar-panel-photovoltaic-4045029/ */}
        <img
          className="sfs-logo-header"
          src="/images/sfs-logo.png"
          alt="solar for schools logo"
        />
        <h1 className="page-heading">Solar For Schools Admin</h1>
      </nav>

      <section className="login-container">
        <form>
          {!mfaRequired && (
            <div style={loginTemplate}>
              <div className="form-group">
                <Label style={{ color: "#f9f9f9"}} name="Email" fontSize="x-large" />
                <Textbox
                  width="25rem"
                  value={email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setEmail(e.target.value)
                  }
                />
              </div>
              <div className="form-group">
                <Label  style={{ color: "#f9f9f9"}} name="Password" fontSize="x-large" />
                <Textbox
                  width="25rem"
                  type="password"
                  value={password}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPassword(e.target.value)
                  }
                />
              </div>
              <div className="form-group">
                <Button onClick={handleSubmit} name={"Login"} fontSize="x-large" />
              </div>
            </div>
          )}
          {mfaRequired &&
            <div style={loginTemplate}>
              <div className="mfa-container">
                <h2 style={{}}>Keeping you secure</h2>
                <p>
                  Your account is protected with <strong>multi-factor authentication (MFA)</strong>.
                </p>
                <p>
                  To finish signing in, enter the code from your MFA device below.
                </p>
                <Label color="var(--solar-blue)" name="MFA Code" fontSize="1rem" />
                <Textbox
                  width="100%"
                  style={{ width: "100%" }}
                  placeholder="Enter MFA Code"
                  value={mfaCode}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setMfaCode(e.target.value)
                  }
                />
                <Button onClick={handleSubmit} margin="10px" name="Continue" fontSize="large" />

              </div>
            </div>
          }

        </form>
      </section>
    </section>
  );
};

interface DispatchProps {
  login: (credentials: ILoginCredentials) => void;
  verifyUserStatus: () => void;
  registerAuthEvent: (registerAuthEvent: IAuthEvent) => void;
  setRedirectURL: (to: string) => void;
}

interface StateProps {
  web: IAdmin;
}
interface IRootState {
  web: any;
}

interface OwnProps { }

type Props = StateProps & OwnProps & DispatchProps;

const mapStateToProps = (state: IRootState): StateProps => ({
  web: state.web.admin,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>
): DispatchProps => {
  return {
    login: async (credentials: ILoginCredentials) => {
      await dispatch(login(credentials));
    },
    verifyUserStatus: async () => {
      await dispatch(verifyUserStatus());
    },
    registerAuthEvent: async (event: IAuthEvent) => {
      await dispatch(registerAuthEvent(event));
    },
    setRedirectURL: async (to: string) => {
      await dispatch(setRedirectURL(to));
    },
  };
};

export default connect<StateProps, DispatchProps, OwnProps, IRootState>(
  mapStateToProps,
  mapDispatchToProps
)(LoginScreen);
