import { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import UserFilters from './spUserFilters';
import { Dropdown, IDropdownOption, Stack } from '@fluentui/react';
import { getSPUsers, setSPUserFilter } from '../../../../store/admin/spUsers/action';
import { RootState } from '../../../../store';
import { ISPUserState } from '../../../../store/admin/spUsers/reducer';
import Loader from '../../common/Loader';
import { PageSize } from '../../common/common';
import SPUserList from './spUserList';


const SpUserScreen = () => {
  const dispatch = useDispatch()
  const { spUsers, totalCount, spUserFilter }: ISPUserState = useSelector<RootState, ISPUserState>((state: RootState) => state.web.spUser)

  useEffect(() => {
    dispatch(getSPUsers())
  }, []);

  const handleOnChangePageSize = (e: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined) => {
    const size: number = (item?.key && parseInt(item?.key.toString())) || 50
    const filters = { ...spUserFilter, page: 1, pageSize: size }
    dispatch(setSPUserFilter(filters))
    dispatch(getSPUsers(filters))
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
      }}
    >
      <Loader />
      <header className="table-header">
        <h1>Solar Portal Users</h1>
        <UserFilters />
        <Stack horizontal horizontalAlign="space-between" verticalAlign="center" style={{ width: '100%' }}>
          <span
            style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem", alignItems: "center" }}
          >
            {totalCount > 0 &&
              <>
                Displaying&nbsp;
                <Dropdown
                  selectedKey={spUserFilter.pageSize?.toString() || "50"}
                  options={PageSize}
                  styles={{ dropdown: { width: "80px" } }}
                  onChange={handleOnChangePageSize}
                />
                &nbsp;/ {totalCount}
              </>}
          </span>
        </Stack>
      </header>
      <SPUserList actions={['edit', 'add', 'delete']} />
    </div>
  );
}
export default SpUserScreen;

