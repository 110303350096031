import { ComboBox, TextField } from '@fluentui/react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { Regions } from '../../common/common';
import { statusOptions } from '../../user/common';
import { IAclRoleState } from '../../../../store/admin/aclRole/reducer';
import { getAclRoles, setAclRoleFilter } from '../../../../store/admin/aclRole/action';

const AclRoleFilters = (props: AclRoleFilterProps) => {
    const dispatch = useDispatch()
    const { aclRoleFilter }: IAclRoleState = useSelector<RootState, IAclRoleState>((state) => state.web.aclRole)
    const handleOnChangeInput = (
        value?: any,
        key?: string
    ) => {
        const params = { ...aclRoleFilter, page: 1, [key!]: value };
        dispatch(setAclRoleFilter(params));
        switch (key) {
            case 'country':
            case 'status':
                dispatch(getAclRoles(params));
                break;
            default:
                if (value?.length === 0 || (value && value.length > 1)) {
                    dispatch(getAclRoles(params));
                }
                break;
        }
    }

    const { name } = aclRoleFilter
    return (
        <div
            className="ms-Grid"
            dir="ltr"
            style={{ minWidth: "700px", maxWidth: "1300px" }}
        >
            <div className="ms-Grid-row">
                <TextField
                    label="Name"
                    placeholder="Enter Name/Slug"
                    type="text"
                    onChange={(e, value) => handleOnChangeInput(value, "name",)}
                    value={name!}
                    className="ms-Grid-col ms-lg8"
                />
            </div>
        </div>
    );
}

interface AclRoleFilterProps {

}

export default AclRoleFilters;

