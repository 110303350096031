import { Action } from "./actionTypes";
import { ISPUser, ISPUserFilter } from "../../../components/admin/solarportal/spUsers/types";

// States' definition
export interface ISPUserState {
  isLoading: boolean;
  spUsers: ISPUser[];
  spUserFilter: ISPUserFilter;
  totalCount: number;
}
export interface State {
  spUser: ISPUserState;
}

const spUser = (
  state: ISPUserState = {
    isLoading: false,
    spUsers: [],
    spUserFilter: {
      page: 1,
      pageSize: 50,
      sortField: "userName",
      sortOrder: "asc",
    },
    totalCount: 0
  },
  action: Action
): ISPUserState => {
  switch (action.type) {
    case "SET_SPUSER_FILTER":
      return { ...state, spUserFilter: action.spUserFilter };

    case "SET_SPUSER_LIST":
      const spUsers = action.reset
        ? action.spUsers
        : [...state.spUsers, ...action.spUsers];
      return { ...state, spUsers, totalCount: action.totalCount };
    case "UPDATE_SPUSER": {
      const stateObj = { ...state };
      const index: any = stateObj.spUsers.findIndex(u => u._id === action.spUser._id)
      if (index > -1) {
        stateObj.spUsers[index].roles = action.spUser.roles;
      }
      return { ...stateObj };

    }
    default:
      return state;
  }
};

export default spUser;
