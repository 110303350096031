import { FontIcon, IColumn, IDropdownOption, TooltipHost } from "@fluentui/react";
import { validateUserFunctionApi } from "../../../../store/client/userFunction";
import { IField } from "../../../dynamic-form/DynamicForm";
import { Regions, requiredMessage } from "../../common/common";
import { StringMap } from "../../common/types";
import { isSortable } from "../../common/utils";
import { userFunctionRoles } from "./common";
import { IAclRole } from "./types";

export const getGeneralFields = (options: StringMap) => {
    
    const isIdThere = options.aclRole?._id ? true : false;
    const fields = [
        {
            label: "Name",
            key: "name",
            type: "string",
            extras: { required: true },
            placeholder: "name",
            className: 'ms-Grid-col ms-lg4'
        },
        {
            label: "Is Deleted",
            key: "isDeleted",
            // extras: { disabled: true },
            type: "checkbox",
            className: 'ms-Grid-col ms-lg2 margin-top-lg'
        }
    ]

    return isIdThere ? fields : fields?.filter((field) => field.key !== 'isDeleted');
}
export const validateAclRole = async (params: IAclRole) => {
    let errors: StringMap = {};
    const { name } = params || {}
    if (!name) errors['name'] = requiredMessage;
    if (!params.permissions?.length) errors['permissions'] = requiredMessage;
    return errors;
}

export const getAclRoleColumns = (params: {
    actions: {
        icon: string,
        onClick: (task: any) => any
    }[]
}): IColumn[] => {
    return [
        {
            key: 'name',
            name: 'Name',
            fieldName: 'name',
            minWidth: 120,
            maxWidth: 150,
            ...isSortable,
            onRender: (item) => <TooltipHost content={item.name} closeDelay={500}>
                <span style={item?.isDeleted ? { color: 'red' } : {}}>{item.name}</span> </TooltipHost>
        },
        {
            name: 'Description',
            key: 'description',
            fieldName: 'description',
            minWidth: 200,
            maxWidth: 600,
            ...isSortable,
            onRender: (item: any) => <TooltipHost content={item.description || ''} closeDelay={500}> 
            <span style={item?.isDeleted ? { color: 'red' } : {}}>{item?.description}</span> </TooltipHost>
        },
        {
            key: 'active',
            name: 'Active',
            fieldName: 'isDeleted',
            minWidth: 75,
            maxWidth: 75,
            ...isSortable,
            onRender: (item: any) => <TooltipHost content={item.isDeleted ? 'No' : 'Yes'} closeDelay={500}> 
            <span style={item?.isDeleted ? { color: 'red' } : {}}>{item.isDeleted ? 'No' : 'Yes'}</span> </TooltipHost>
        },
        {
            key: 'action',
            name: 'Action',
            fieldName: '-',
            ariaLabel: "Action",
            minWidth: 150,
            maxWidth: 300,
            onRender: (taskType) => {
                return (
                    <div className="action-icons-container">
                        {params.actions.map((action: { icon: string | undefined; onClick: (arg0: any) => void; }) => {
                            return <FontIcon
                                iconName={action.icon}
                                onClick={() => action.onClick(taskType)} />;
                        })}
                    </div>
                );
            },
        }
    ];
}

export const initRole: IAclRole = {
    name: "",
    description: "",
    permissions: []
};
