import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../index";
import { isLoading } from "../action";
import { toast } from "react-toastify";
import { IAclRole, IAclRoleFilter } from "../../../components/admin/solarportal/aclRoles/types";
import { createAclRoleApi, deleteAclRoleApi, getAclRolesApi, updateAclRoleApi } from "../../client/solarportal/aclRole";

// Action Creators

export const setAclRoleFilter = (aclRoleFilter: IAclRoleFilter) => {
  return { type: "SET_ACL_ROLE_FILTER", aclRoleFilter };
};

export const setAclRoleList = (
  aclRoles: IAclRole[],
  totalCount: number,
  reset: boolean = true
) => {
  return { type: "SET_ACL_ROLE_LIST", aclRoles, totalCount, reset };
};

export const updateAclRoleList = (aclRole: IAclRole) => {
  return { type: "UPDATE_ACL_ROLE", aclRole };
};

export const delAclRole = (_id: string) => {
  return { type: "DELETE_ACL_ROLE", _id };
};

export const getAclRoles =
  (params?: IAclRoleFilter, reset: boolean = true) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        let reqBody = params;
        if (!reqBody) {
          const filters: IAclRoleFilter =
            getState().web.aclRole.aclRoleFilter;
          reqBody = { ...filters };
        }
        const { data: aclRoles, totalCount } = await getAclRolesApi(reqBody);
        dispatch(setAclRoleList(aclRoles, totalCount, reset));
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };

export const createUpdateAclRole =
  (params: IAclRole) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        const { data: aclRole, message: msg } = params._id
          ? await updateAclRoleApi(params)
          : await createAclRoleApi(params);
        dispatch(updateAclRoleList(aclRole));
        toast.success(msg);
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };

export const deleteAclRole =
  (aclRole: IAclRole) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        const { msg } = await deleteAclRoleApi(aclRole);
        dispatch(delAclRole(aclRole._id!.toString()));
        toast.success(msg);
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };
