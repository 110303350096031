import { ChoiceGroup, ComboBox, Dropdown, IComboBoxOption, TextField } from '@fluentui/react';
import _ from 'lodash';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { statusOptions } from './common';
import { ISPUserState } from '../../../../store/admin/spUsers/reducer';
import { RootState } from '../../../../store';
import { StringMap } from '../../common/types';
import { getSPUsers, setSPUserFilter } from '../../../../store/admin/spUsers/action';
import { dropdownStyles, Regions } from '../../common/common';

const SPUserFilters = (props: UserFilterProps) => {
    const dispatch = useDispatch()
    const { spUserFilter }: ISPUserState = useSelector<RootState, ISPUserState>((state) => state.web.spUser)
    const setFilters = (filters: StringMap) => dispatch(setSPUserFilter(filters));

    const handleOnChange = async (
        key: string,
        value?: any
    ) => {
        const params = { ...spUserFilter, page: 1, [key]: value };
        setFilters(params);
        handleUpdateFilters(params)
    }

    const handleUpdateFilters = useCallback(
        (filters: any) => {
            dispatch(getSPUsers(filters));
        },
        []
    );

    const { search, region, } = spUserFilter
    return (
        <div
            className="ms-Grid"
            dir="ltr"
            style={{ minWidth: "700px", maxWidth: "1300px" }}
        >
            <div className="ms-Grid-row">
                <TextField
                    label="User Name / Email"
                    placeholder="User Name or Email"
                    onChange={(e, value) => handleOnChange('search', value || '')}
                    className="ms-Grid-col ms-lg6"
                    value={search!} />
                <Dropdown
                    dropdownWidth='auto'
                    label="Region"
                    onChange={(e, item) => handleOnChange("region", item?.key?.toString())}
                    selectedKey={region || 'all'}
                    options={Regions.map((r) => ({ key: r.key.toLowerCase(), text: r.text }))}
                    styles={dropdownStyles}
                    className="inlineflex ms-Grid-col ms-lg2"
                />
            </div>
        </div>
    );
}

interface UserFilterProps {

}

export default SPUserFilters;

