import { ComboBox, TextField } from '@fluentui/react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { setUserFunctionFilter } from '../../../../store/admin/userFunction/action';
import { AclAccessesOptions } from '../../common/common';
import { IAclAccessState } from '../../../../store/admin/aclAccess/reducer';
import { getAclAccesses, setAclAccessFilter } from '../../../../store/admin/aclAccess/action';

const AclAccessFilters = (props: AclAccessFilterProps) => {
    const dispatch = useDispatch()
    const { aclAccessFilter }: IAclAccessState = useSelector<RootState, IAclAccessState>((state) => state.web.aclAccess);
    const handleOnChangeInput = (
        value?: any,
        key?: string
    ) => {
        const params = { ...aclAccessFilter, page: 1, [key!]: value };
        dispatch(setAclAccessFilter(params));
        switch (key) {
            case 'type':
                if (value === "all") {
                    delete params.type;
                }
                dispatch(getAclAccesses(params));
                break;
            default:
                if (value?.length === 0 || (value && value.length > 1)) {
                    dispatch(getAclAccesses(params));
                }
                break;
        }
    }

    const { name, type } = aclAccessFilter
    return (
        <div
            className="ms-Grid"
            dir="ltr"
            style={{ minWidth: "700px", maxWidth: "1300px" }}
        >
            <div className="ms-Grid-row">
                <TextField
                    label="Name"
                    placeholder="Enter Name"
                    type="text"
                    onChange={(e, value) => handleOnChangeInput(value, "name",)}
                    value={name!}
                    className="ms-Grid-col ms-lg6"
                />

                <ComboBox
                    label="Type"
                    selectedKey={type ? type : "all"}
                    options={AclAccessesOptions}
                    autoComplete={"on"}
                    onChange={(e, item) => handleOnChangeInput(item?.key, 'type')}
                    className="ms-Grid-col ms-lg3"
                />
            </div>
        </div>
    );
}

interface AclAccessFilterProps {

}

export default AclAccessFilters;

