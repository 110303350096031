import { toast } from "react-toastify";
import axios from "axios";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

import { SetSchoolList, SetSchools, SetSchoolsFilter } from "./actionTypes";
import { host } from "../../config";
import { RootState } from "../../index";
import { DropdownParams } from "../../../components/admin/common/types";
import { isLoading, setAuthToken } from "../action";
import { clearTokenParams, generateQueryString } from "../../../utils/util";
import { ParsedUrlQueryInput } from "querystring";
import { ISchoolsFilter } from "../../../components/admin/schools/types";
import {
  IEstablishment,
  IEstablishment as IEstablishmentDocument,
} from "@solarforschools/sfs-core/dist/school/types";
import {
  assignSchoolApi,
  createScchoolAPi,
  deleteSchoolApi,
  exportSchoolsApi,
  getSchoolCountriesApi,
  getSchoolFiltersApi,
  getSchoolsApi,
  updateSchoolApi,
} from "../../client/school";
import { countries } from "../../../components/admin/common/common";
import _ from "lodash";
import { getUsersApi } from "../../client/user";
import { downloadFileFromStream } from "../../../components/admin/common/utils";
import { unlinkPFApi } from "../../client/projectFolder";
import { IProjectFolderLink } from "@solarforschools/sfs-core/dist/types";

// Action Creators
export const setSchoolsFilter = (data: ISchoolsFilter): SetSchoolsFilter => {
  return { type: "SET_SCHOOLS_FILTER", data };
};

export const setUsers = (users: DropdownParams[]) => {
  return { type: "SET_USERS_FILTER", users };
};

export const setProjects = (projects: DropdownParams[]) => {
  return { type: "SET_PROJECTS_FILTER", projects };
};

export const setSchools = (schools: IEstablishmentDocument[]): SetSchools => {
  return { type: "SET_SCHOOLS", schools };
};

export const setSchoolList = (
  schools: IEstablishmentDocument[],
  totalCount: number,
  replace: boolean
): SetSchoolList => {
  return { type: "SET_SCHOOL_LIST", schools, totalCount, replace };
};

export const setSchoolsTotalCount = (totalCount: number) => {
  return { type: "SET_SCHOOLS_TOTAL_COUNT", totalCount };
};

export const setSchoolCountries = (countries: any) => {
  return { type: "SET_SCHOOLS_COUNTRIES", countries };
};

export const setSchoolServices = (services: string[]) => {
  return { type: "SET_SCHOOLS_SERVICES", services };
};
export const setSchoolServices2 = (services2: string[]) => {
  return { type: "SET_SCHOOLS_SERVICES2", services2 };
};
export const setSchoolStatus = (status: string) => {
  return { type: "SET_SCHOOLS_STATUS", status };
};

export const delSchool = (school: IEstablishment) => {
  return { type: "DELETE_SCHOOL", school };
};

export const updateSchool = (school: IEstablishment) => {
  return { type: "UPDATE_SCHOOL", school };
};

export const fetchSchoolsCountries = (
  query?: any,
  isShowLoader: boolean = true
) => {
  // Invoke API
  return async (
    dispatch: ThunkDispatch<{}, RootState, AnyAction>
  ): Promise<void> => {
    return new Promise<void>(async (resolve) => {
      try {
        if (isShowLoader) dispatch(isLoading(true));
        const res = await getSchoolCountriesApi(query);
        // const countriesList: any = {};
        // countries.forEach((country) => {
        //   countriesList[country.key] = country.text;
        // });
        if (res.data) {
          let data = [];
          for (const c of res.data) {
            data.push({ key: c, text: c });
          }
          data = _.orderBy(data, ["text"], ["asc"]);
          data.unshift({ key: "all", text: "All" });
          dispatch(setSchoolCountries(data));
        }
        if (isShowLoader) dispatch(isLoading(false));
        resolve();
      } catch (err: any) {
        if (err?.response?.status === 404) {
          toast.error(err.response.data.msg);
        }
        if (isShowLoader) dispatch(isLoading(false));
      }
    });
  };
};

export const fetchSchoolsFilters = (
  query?: any,
  isShowLoader: boolean = true
) => {
  // Invoke
  return async (
    dispatch: ThunkDispatch<{}, RootState, AnyAction>
  ): Promise<void> => {
    return new Promise<void>(async (resolve) => {
      try {
        if (isShowLoader) dispatch(isLoading(true));
        const { services, services2, status } = await getSchoolFiltersApi(query);
        dispatch(setSchoolServices(services))
        dispatch(setSchoolServices2(services2))
        dispatch(setSchoolStatus(status));

        if (isShowLoader) dispatch(isLoading(false));
        resolve();
      } catch (err: any) {
        if (err?.response?.status === 404) {
          toast.error(err.response.data.msg);
        }
        if (isShowLoader) dispatch(isLoading(false));
      }
    });
  };
};
export const fetchProjects = (query?: any, isShowLoader: boolean = true) => {
  // Invoke API
  return async (
    dispatch: ThunkDispatch<{}, RootState, AnyAction>
  ): Promise<void> => {
    return new Promise<void>(async (resolve) => {
      try {
        if (isShowLoader) dispatch(isLoading(true));
        const res = await axios.get(
          `${host}/projects?${generateQueryString(
            query as ParsedUrlQueryInput
          )}`
        );

        if (res.status === 200) {
          const projects = [{ key: "all", text: "Select" }];
          for (const p of res.data.projects) {
            projects.push({ key: p._id.toString(), text: p.name });
          }
          dispatch(setProjects(projects));
        }
        if (isShowLoader) dispatch(isLoading(false));
        resolve();
      } catch (err: any) {
        if (err?.response?.status === 404) {
          toast.error(err.response.data.msg);
        }
        if (isShowLoader) dispatch(isLoading(false));
      }
    });
  };
};

export const fetchUsers = (query?: any, isShowLoader: boolean = true) => {
  // Invoke API
  return async (
    dispatch: ThunkDispatch<{}, RootState, AnyAction>
  ): Promise<void> => {
    return new Promise<void>(async (resolve) => {
      try {
        if (isShowLoader) dispatch(isLoading(true));
        const res = await getUsersApi(query);
        if (res?.users) {
          const users = [{ key: "all", text: "Select" }];
          for (const u of res.users) {
            users.push({
              key: u._id.toString(),
              text: `${u.userName} (${u.userEmail})`,
            });
          }
          dispatch(setUsers(users));
        }
        if (isShowLoader) dispatch(isLoading(false));
        resolve();
      } catch (err: any) {
        if (err?.response?.status === 404) {
          toast.error(err.response.data.msg);
        }
        if (isShowLoader) dispatch(isLoading(false));
      }
    });
  };
};

export const deleteSchool =
  (data: IEstablishment) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        const { msg } = await deleteSchoolApi(data.slug);
        dispatch(delSchool(data));
        toast.success(msg);
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };

export const createUpdateSchool =
  (params: IEstablishment) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        const { school, msg } = params._id
          ? await updateSchoolApi(params)
          : await createScchoolAPi(params);
        dispatch(updateSchool(school));
        toast.success(msg);
        dispatch(isLoading(false));
        return school;
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };

export const uploadImage = async (fileContent: any) => {
  try {
    const formData = new FormData();
    formData.append("files", fileContent.file);
    const res = await axios.post(
      `${host}/school/image/${fileContent.slug}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const deleteImage = async (slug: string, image: string) => {
  try {
    const res = await axios.delete(
      `${host}/school/image/${slug}?image=${image}`
    );
    return res;
  } catch (e) {
    return null;
  }
};

export const getSchoolBySlug = async (slug: string): Promise<any> => {
  try {
    const { data } = await axios.get(`${host}/school/${slug}`);
    return data.school;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const assignSchool =
  (params: any) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        const { msg } = await assignSchoolApi(params);
        toast.success(msg);
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };

export const exportSchools =
  (params?: ISchoolsFilter, reset: boolean = true) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        let reqBody = params;
        if (!reqBody) {
          const filters: ISchoolsFilter = getState().web.school.schoolsFilter;
          reqBody = { ...filters };
        }
        const res = await exportSchoolsApi(reqBody);
        downloadFileFromStream({
          data: res.data,
          filename: "Schools",
          type: "xlsx",
        });
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
        toast.error("Something happend wrong");
      }
    };

export const fetchSchools =
  (params?: ISchoolsFilter, reset: boolean = true) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      // Add token in axios header, if authorization is undefined
      let isThereToken = axios.defaults.headers.common["authorization"];
      if (isThereToken === undefined) {
        let token = localStorage.getItem("token") as string;
        setAuthToken(token);
      }
      clearTokenParams();

      try {
        dispatch(isLoading(true));
        let reqBody = params;
        if (!reqBody) {
          const filters: ISchoolsFilter = getState().web.school.schoolsFilter;
          reqBody = { ...filters };
        }
        const { schools, totalCount } = await getSchoolsApi(reqBody);
        dispatch(setSchoolList(schools, totalCount, reset));
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };
export const unlinkProjectFolder =
  (school: IEstablishment) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        const { msg } = await unlinkPFApi(
          school.projectFolder?.driveItemId!,
          school.projectFolder?.driveId!
        );
        school.projectFolder = {} as IProjectFolderLink;
        dispatch(updateSchool(school));
        toast.success(msg);
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };

export const saveThisConfig = (configData: ISchoolsFilter) => {
  return async (dispatch: ThunkDispatch<any, any, any>) => {
    try {
      dispatch(isLoading(true));
      const res = await axios.post(`${host}/configs/region`, {
        data: configData,
      });
      if (res.status === 200) {
        dispatch(isLoading(false));
        toast.success("Config saved successfully");
        return;
      }
      dispatch(isLoading(false));
      toast.error("Something went wrong in saving the config");
    } catch (e) {
      console.log(e);
      dispatch(isLoading(false));
      toast.error("Something went wrong in saving the config");
      return null;
    }
  };
};
