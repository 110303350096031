import { ISystem } from "@solarforschools/sfs-core";
import { StringMap } from "../common/types";

export const a = 100;

export const meterReadingTypes = [
    { key: 5, text: "Automatic reading provided by SFS (default)" },
    { key: 1, text: "Manual reading provided by school" },
    { key: 2, text: "Reading provided by meter operator" },
    { key: 3, text: "Calculated value" },
    { key: 4, text: "Manual reading provided by SFS" },
]

export const MeterTypes = [
    { key: 'B', text: 'B' },
    { key: 'G', text: 'G' },
    { key: 'E', text: 'E' },
    { key: 'EV', text: 'EV' },
    { key: 'I', text: 'I' },
]

export const ReadingTypesMap: any = {
    1: "Manual reading provided by school",
    2: "Reading provided by meter operator",
    3: "Calculated value",
    4: "Manual reading provided by SFS",
    5: "Automatic reading provided by SFS (default)",
}

const filterSystems = (systems: ISystem[], filter: StringMap) => {
    let data = [...systems]
    data = filter.schoolSlug && filter.schoolSlug !== 'all' ? data.filter(s => [...s.schools.map(s => s.slug)].includes(filter.slug || filter.schoolSlug)) : data
    data = filter.system && filter.system !== 'all' ? data.filter(s => s.id?.toString() === filter?.system?.toString()) : data
    return data
}

export const filterSystemsOptions = (systems: ISystem[], filter: StringMap) => {
    const data = filterSystems(systems, filter)
    return data.map((s: { id: any; name: any; }) => {
        return {
            key: s.id.toString(),
            text: `${s.name} (${s.id})`,
            name: s.name
        }
    })
}

export const filterSystemsMeterOptions = (systems: ISystem[], filter: StringMap, onlyMid: boolean = false) => {
    const data = filterSystems(systems, filter)
    let metersData = data.map((obj: { meters: any; }) => obj.meters);
    metersData = flatten(metersData);
    const d = metersData.filter(m => m?.mid)
    const meters = d.map((m: any) => {
        const key = onlyMid ? m.mid : `${m.mid}--${m.type}--${m.MSN}`
        return {
            key,
            text: `${m.mid}-${m.deviceType}-${m.type}`
        }
    })
    const msns = d.map((m: any) => {
        const key = m.MSN
        return {
            key,
            text: key
        }
    })
    return { meters, msns }
}

const flatten = (arr1: any[]): any =>
    arr1.reduce((acc: string | any[], val: any) => (Array.isArray(val) ? acc.concat(flatten(val)) : acc.concat(val)), []);
