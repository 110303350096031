import { ObjectId } from "mongodb";
import { IField } from "../../dynamic-form/DynamicForm";
import { stages } from "../schools/common";

export const stageFields: IField[] = [
    {
        key: 'activeStage.name',
        label: 'Stage',
        type: 'dropdown',
        options: stages,
        //   className: 'ms-Grid-col ms-lg2',
    }, {
        key: 'activeStage.active',
        label: 'Is Active',
        type: 'checkbox',
        className: 'margin-top-lg',
    },
];

export interface IWhenWho {
    date: Date | null;
    user: ObjectId | null;
  }
  