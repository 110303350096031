import { Action } from "./actionTypes";
import { IAclPermission, IAclPermissionFilter } from "../../../components/admin/solarportal/aclPermissions/types";

// States' definition
export interface IAclPermissionState {
  isLoading: boolean;
  aclPermissions: any[];
  aclPermissionFilter: IAclPermissionFilter;
  totalCount: number;
}
export interface State {
  aclPermissionsState: IAclPermissionState;
}

const aclPermission = (
  state: IAclPermissionState = {
    isLoading: false,
    aclPermissions: [],
    aclPermissionFilter: {
      page: 1,
      pageSize: 50,
      sortField: "name",
      sortOrder: "desc",
    },
    totalCount: 0,
  },
  action: Action
): IAclPermissionState => {
  switch (action.type) {
    case "SET_ACL_PERMISSION_FILTER":
      return { ...state, aclPermissionFilter: action.aclPermissionFilter };
    case "SET_ACL_PERMISSION_LIST":
      const aclPermissions = action.reset
        ? action.aclPermissions
        : [...state.aclPermissions, ...action.aclPermissions];
      return { ...state, aclPermissions, totalCount: action.totalCount };
    case "DELETE_ACL_PERMISSION": {
      const stateObj = { ...state };
      const aclPermissions: IAclPermission[] = stateObj.aclPermissions.filter(
        (s) => s._id !== action._id
      );
      const aclPermissionFilter = { ...stateObj.aclPermissionFilter };
      const { page, pageSize } = aclPermissionFilter;
      return {
        ...state,
        aclPermissions,
        totalCount: stateObj.totalCount,
        aclPermissionFilter: {
          ...aclPermissionFilter,
          page: Math.ceil((aclPermissions.length / page!) * pageSize!),
        },
      };
    }
    case "UPDATE_ACL_PERMISSION": {
      const aclPermissions = [...state.aclPermissions];
      const index = aclPermissions.findIndex(
        (s) => s._id === action.aclPermission?._id
      );
      if (index > -1) {
        aclPermissions[index] = action.aclPermission;
      } else {
        aclPermissions.unshift(action.aclPermission);
      }
      return {
        ...state,
        aclPermissions,
        totalCount: index > -1 ? state.totalCount : state.totalCount + 1,
      };
    }
    default:
      return state;
  }
};

export default aclPermission;
