const getHost = () => {
  switch (process.env.REACT_APP_CORE_API) {
    case "local":
      return process.env.REACT_APP_CORE_API_LOCAL;
    case "beta":
      return process.env.REACT_APP_CORE_API_BETA;
    case "production":
      return process.env.REACT_APP_CORE_API_PROD;
  }
};

const getContentHost = () => {
  switch (process.env.REACT_APP_MOBILE_API) {
    case "local":
      return process.env.REACT_APP_MOBILE_API_LOCAL;
    case "beta":
      return process.env.REACT_APP_MOBILE_API_BETA;
    case "production":
      return process.env.REACT_APP_MOBILE_API_PROD;
  }
};

const getWebsiteHost = () => {
  switch (process.env.REACT_APP_WEBSITE_HOST) {
    case "local":
      return process.env.REACT_APP_WEBSITE_LOCAL;
    case "beta":
      return process.env.REACT_APP_WEBSITE_BETA;
    case "production":
      return process.env.REACT_APP_WEBSITE_PRODUCTION;
    default:
      return "https://solarforschools.co.uk"
  }
}

const getTpHost = () => {
  switch (process.env.REACT_APP_TEACHERS_PORTAL_HOST) {
    case "beta":
      return process.env.REACT_APP_TEACHERS_PORTAL_BETA;
    case "production":
      return process.env.REACT_APP_TEACHERS_PORTAL_PRODUCTION;
  }
};

const getSolaportaApiHost = () => {
  switch (process.env.REACT_APP_SOLAR_PORTAL_API) {
    case "local":
      return process.env.REACT_APP_SOLAR_PORTAL_API_LOCAL;
    case "beta":
      return process.env.REACT_APP_SOLAR_PORTAL_API_BETA;
    case "production":
      return process.env.REACT_APP_SOLAR_PORTAL_API_PROD;
  }
};


export const host = getHost();
export const contentHost = getContentHost();
export const websiteHost = getWebsiteHost();
export const tpHost = getTpHost();
export const solaportaApiHost = getSolaportaApiHost();

export const awsUrlHost = `https://sfs-cdn.s3.eu-central-1.amazonaws.com`;
