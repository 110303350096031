import { useState } from 'react';
import { ActionButton, CheckboxVisibility, Dropdown, IDropdownOption, ScrollablePane, ScrollbarVisibility } from '@fluentui/react';
import { SelectionMode, IColumn } from '@fluentui/react/lib/index';
import { useDispatch, useSelector } from "react-redux";


import { RootState } from '../../../../store';
import { DetailListStickyHeader } from '../../common/DetailListStickyHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, } from '@fortawesome/free-solid-svg-icons';
import { ConfirmDialog } from '../../common/ConfirmDialog';
import { getTaskTypes } from '../../../../store/admin/taskType/action';
import DetailsListWapper from '../../../common/DetailsListWapper';
import { PageSize } from '../../common/common';
import Pagination from '../../common/Pagination';
import { IAclPermissionState } from '../../../../store/admin/aclPermission/reducer';
import { IAclPermission } from './types';
import { deleteAclPermission, getAclPermissions, setAclPermissionFilter } from '../../../../store/admin/aclPermission/action';
import AclPermissionFilters from './AclPermissionFilters';
import OpenAclPermissionModal from './AclPermissionDialog';
import { getAclPermissionColumns } from './helper';

const AclPermissionList = ({ actions }: Props = { actions: [] }) => {
  const dispatch = useDispatch();
  const { aclPermissions, totalCount, aclPermissionFilter }: IAclPermissionState = useSelector<RootState, IAclPermissionState>((state: RootState) => state.web.aclPermission)

  const handleOnDelete = async (aclPermission: IAclPermission) => {
    const confirm = await ConfirmDialog({
      dialogContentProps: {
        title: "Delete Acl Permission",
        closeButtonAriaLabel: "Close",
        subText: `Are you want to delete ${aclPermission.name}`,
      },
    });
    if (confirm) dispatch(deleteAclPermission(aclPermission));
  };

  const handleOnEdit = async (aclPermission: IAclPermission) => {
    await OpenAclPermissionModal({ aclPermission })
  };

  const headerRow = getAclPermissionColumns({
    actions: [
      {
        icon: 'Edit',
        onClick: handleOnEdit,
      },
      // {
      //   icon: "Trash",
      //   onClick: handleOnDelete
      // }
    ]
  })
  const [columns] = useState<IColumn[]>(headerRow)

  const handleOnSort = (field: string, order: string) => {
    const params = { ...aclPermissionFilter, sortField: field, sortOrder: order };
    dispatch(setAclPermissionFilter(params));
    dispatch(getAclPermissions(params))
  }

  const loadNextPage = (p: any) => {
    dispatch(setAclPermissionFilter({ ...aclPermissionFilter, ...p }))
    dispatch(getAclPermissions())
  }
  const handleOnChangePageSize = (e: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined) => {
    const size: number = (item?.key && parseInt(item?.key.toString())) || 50
    const filters = { ...aclPermissionFilter, page: 1, pageSize: size }
    dispatch(setAclPermissionFilter(filters))
    dispatch(getAclPermissions(filters))
  };

  return (
    <>
      <div
        style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem" }}
        className="margin-top-md"
      >
        <div style={{ flex: "20%" }}>
          <span
            style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem", alignItems: "center" }}
          >
            {totalCount > 0 &&
              <>
                Displaying&nbsp;
                <Dropdown
                  selectedKey={aclPermissionFilter.pageSize?.toString() || "50"}
                  options={PageSize}
                  styles={{ dropdown: { width: "80px" } }}
                  onChange={handleOnChangePageSize}
                />
                &nbsp;/ {totalCount}
              </>}
          </span>
        </div>

        <ActionButton
          allowDisabledFocus
          style={{ textAlign: 'right' }}
          onClick={async () => await OpenAclPermissionModal({})}
        ><FontAwesomeIcon icon={faPlus} size='1x' color='#106ebe' />  &nbsp; Add permission
        </ActionButton>
      </div>

      {totalCount > 0 ? (
        <>
          <div className="data-list-container">
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              <DetailsListWapper
                items={aclPermissions?.sort((a, b) => a?.isDeleted - b?.isDeleted)}
                columns={columns}
                selectionMode={SelectionMode.none}
                onRenderDetailsHeader={DetailListStickyHeader}
                compact={true}
                checkboxVisibility={CheckboxVisibility.hidden}
                canLoadMore={aclPermissions.length < totalCount}
                // onRequestLoadMore={loadNextPage}
                onSort={(field, order) => handleOnSort(field, order)}
              />
            </ScrollablePane>
          </div>
          <Pagination
            totalRecords={totalCount}
            onPaginate={(page) => loadNextPage({ page })}
            currentPage={aclPermissionFilter?.page!}
            pageSize={aclPermissionFilter.pageSize!}
          />
        </>
      ) : (
        <p style={{ paddingLeft: "1rem", textAlign: "center" }}>
          No Data Found!
        </p>
      )}
    </>
  )
};

export default AclPermissionList;
interface OwnProps {
  actions: string[];
}

type Props = OwnProps;

