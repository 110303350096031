import { DefaultButton, PrimaryButton, Stack, Sticky, StickyPositionType } from "@fluentui/react"
import _ from "lodash"
import { useMemo, useState } from "react"
import ReactDOM from "react-dom"
import { Provider, useDispatch } from "react-redux"
import store from "../../../../store"
import DynamicForm from "../../../dynamic-form/DynamicForm"
import ModalDialog from "../../common/ModalDialog"
import { getGeneralFields, initAclAccess, validateAclAccessFunction } from "./helper"
import slugify from "slugify"
import { IUserFunction } from "@solarforschools/sfs-core/dist/settings/types"
import { createUpdateUserFunction } from "../../../../store/admin/userFunction/action"
import { IAclAccess } from "./types"
import { createUpdateAclAccess } from "../../../../store/admin/aclAccess/action"


export const OpenAclAccessModal = (props?: any) => {
  return new Promise<IAclAccess | undefined>((resolve, reject) => {
    const mountTarget = document.createElement('div')
    document.body.appendChild(mountTarget)
    const callback = (aclAccess?: IAclAccess) => {
      resolve(aclAccess);
      ReactDOM.unmountComponentAtNode(mountTarget)
      mountTarget.remove()
    }
    ReactDOM.render(
      <Provider store={store}>
        <ModalDialog
          isModalOpen={true}
          title="Add/Edit Access"
          onDismiss={() => callback(undefined)}
          containerClassName={"modal-size-sm"}
        >
          <EditAclAccessDialog
            {...props}
            onSave={(aclAccess: IAclAccess | undefined) => callback(aclAccess)}
            onCancel={() => callback(undefined)}
          />
        </ModalDialog>
      </Provider>,
      mountTarget
    )
  })
}

const EditAclAccessDialog = (props: AclAccessProps) => {
  const dispatch = useDispatch();

  const [aclAccess, setAclAccess] = useState<IAclAccess>(_.cloneDeep(props.aclAccess || initAclAccess as IAclAccess))
  const [errors, setErrors] = useState<any>({});
  
  const generalFields = useMemo(() => getGeneralFields({ aclAccess }), [aclAccess]);

  const handleOnSave = async () => {
    const errors = await validateAclAccessFunction(aclAccess);
    if (Object.keys(errors).length) {
      // const tabErrors = getTabErrors(errors);
      setErrors(errors);
      // setTabErrors(tabErrors);
      return;
    }
    await dispatch(createUpdateAclAccess(aclAccess))
    props.onSave(aclAccess)
  }

  const onPivotLinkClick = async (props: any) => { };

  const handleOnChangeInput = (key: string, value: any) => {

    let data: IAclAccess = _.cloneDeep(aclAccess);
    switch (key) {
      case 'any':
        
        break;
      default:
        setAclAccess(_.set({ ...data }, key, value))
        break;
    }
  }
  return (
    <div className="edit-record">
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <DynamicForm
            fields={generalFields}
            data={aclAccess}
            onChange={handleOnChangeInput}
            errors={errors}
          />
        </div>

      </div>
      <Sticky stickyPosition={StickyPositionType.Footer}>
        <Stack horizontal horizontalAlign="center">
          <Stack.Item>
            <PrimaryButton
              text="Save"
              onClick={handleOnSave}
              className="btn-primary"
            />
            <DefaultButton onClick={props.onCancel} text="Cancel" />
          </Stack.Item>
        </Stack>
      </Sticky>
    </div>
  );
};

interface AclAccessProps {
  onCancel?: () => void;
  onSave: (aclAccess: any) => void;
  aclAccess?: IAclAccess;
}

export default OpenAclAccessModal;
