import { useEffect } from 'react'
import { useDispatch } from "react-redux";
import { getUserFunctions } from '../../../../store/admin/userFunction/action';

import Loader from '../../common/Loader';
import UserFunctionFilters from './AclRoleFilters';
import UserFunctionList from './AclRoleList';
import AclRoleFilters from './AclRoleFilters';
import AclRoleList from './AclRoleList';
import { getAclRoles } from '../../../../store/admin/aclRole/action';

const UserRoleScreen = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAclRoles())
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
      }}
    >
      <Loader />
      <header className="table-header">
        <h1>Acl Roles</h1>
        <AclRoleFilters />
      </header>
      <AclRoleList actions={['add', 'edit', 'delete']} />
    </div>
  );
}
export default UserRoleScreen;

