import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";

import Loader from '../common/Loader';
import { RootState } from '../../../store';
import { PrimaryButton, Stack, TextField, Toggle } from '@fluentui/react';
import { confrimMfaConfigApi, getUserByIdApi, getUserQrCodeApi } from '../../../store/client/user';
import { toast } from 'react-toastify';
import { isLoading } from '../../../store/admin/action';
import { QRCodeSVG } from "qrcode.react";

const AccountScreen = () => {
  const dispatch = useDispatch()
  const userId = useSelector<RootState>((state: RootState) => state.web.admin.user?.id)
  const [user, setUser] = useState<any>(null)
  const [payLoad, setPayLoad] = useState<any>()
  const [mfaCode, setMfaCode] = useState("");
  const [qrCode, setQrCode] = useState(null);

  const getAccountDetails = async (userId: string) => {
    try {
      dispatch(isLoading(true))
      const { user } = await getUserByIdApi(userId)
      setUser(user)
      setPayLoad(user)
    } catch (error: any) {
      const { msg } = error?.response?.data || { msg: 'Error while confirming MFA configuration' }
      console.log(error)
      toast.error(msg)
    } finally {
      dispatch(isLoading(false))
    }

  }

  useEffect(() => {
    if (userId)
      getAccountDetails(userId as string)

  }, [userId])

  const handleMfaToggle = async () => {
    const isMFAEnabled = !payLoad.isMFAEnabled
    setPayLoad({ ...payLoad, isMFAEnabled, isChanged: true })
    setUser({ ...user, isMFAEnabled: !user.isMFAEnabled });
    setMfaCode("")
    if (isMFAEnabled) {
      try {
        const { qrcode } = await getUserQrCodeApi(payLoad._id)
        setQrCode(qrcode)
      } catch (error: any) {
        console.log(error)
        const { msg } = error?.response?.data || { msg: 'Error while confirming MFA configuration' }
      }
    }
  };

  const handleConfirm = async () => {
    if (!mfaCode) {
      toast.error('Please enter MFA code')
      return
    }
    try {
      const { msg } = await confrimMfaConfigApi({ _id: payLoad._id, isMFAEnabled: payLoad.isMFAEnabled, mfaCode })
      toast.success(msg)
      setPayLoad({ ...payLoad, isChanged: false })

    } catch (error: any) {
      console.log(error)
      const { msg } = error?.response?.data || { msg: 'Error while confirming MFA configuration' }
      toast.error(msg)
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        position: "relative",
      }}
    >
      <Loader />
      <header className="table-header">
        <h1>Account</h1>
      </header>
      <div className="data-list-container" style={{ textAlign: "center" }}>
        {user && (
          <>
            <img
              src="/images/profile.svg"
              alt="Avatar"
              style={{
                borderRadius: "50%",
                width: "150px",
                height: "150px",
                border: "1px solid #000",
              }}
            />
            <p><h3>{user?.userName}</h3></p>
            <div style={{ marginTop: "20px", width: "300px" }}>
              <form style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                <Toggle
                  label="MFA Enabled/Disabled"
                  checked={user?.isMFAEnabled}
                  inlineLabel
                  onText="Enabled"
                  offText="Disabled"
                  onChange={handleMfaToggle}
                />
                {payLoad?.isChanged && (
                  <>
                    {payLoad.isMFAEnabled && qrCode && (
                      <div className="mfa-container" style={{ textAlign: "center" }}>
                        <h3>Scan this QR code for MFA</h3>
                        {/* <p><small><i>If you have already scanned the code in your authenticator app, there is no need to scan it again; simply enter the MFA code.</i></small></p> */}

                        <img src={qrCode!} style={{ marginTop: 20 }} />
                      </div>
                    )}
                    <TextField
                      label="Enter MFA Code"
                      value={mfaCode}
                      onChange={(e, newValue) => setMfaCode(newValue || "")}
                    />
                    <PrimaryButton onClick={handleConfirm}>{payLoad.isMFAEnabled ? "Enable" : "Disable"}</PrimaryButton>
                  </>
                )}


              </form>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
export default AccountScreen;

