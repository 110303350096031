import React, { useEffect, useState } from 'react';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { DatePicker, Dropdown, IDropdownOption, Stack, Sticky, StickyPositionType, TextField } from '@fluentui/react';
import 'office-ui-fabric-react/dist/css/fabric.css';
import _ from 'lodash';
import Moment from 'moment'
import { InitBillingCycle } from './common';
import { IBillingCycle } from './types'
import { dropdownStyles, FundingOptions, } from '../common/common'
import { IBilling } from '../../../store/admin/billing/reducer';
import { RootState } from '../../../store';
import { useSelector } from 'react-redux';
import { ConfirmDialog } from '../common/ConfirmDialog';
import { getSystemFilter } from '../../../store/admin/billing/action';
import { getSystems } from '../../../store/client/system';
import SystemPicker from '../common/SystemPicker';


export const AddBillingPeriod = ({
  onCancel,
  onSave
}: Props) => {
  const [billingCycle, setBillingCycle] = useState<IBillingCycle>({
    systemId: "all"
  })

  const billing: IBilling = useSelector<RootState, IBilling>((state) => state.web.billing)
  const periods: any[] = billing.periodFilter

  useEffect(() => {
    setBillingCycle(InitBillingCycle())
  }, [])

  const handleOnChangeInput = (key: string, value: any) => {
    let billingCycleObj = { ...billingCycle }
    switch (key) {
      case 'selectedSystems':
        billingCycleObj = { ...billingCycleObj, systemId: value[0]?.key }
        break
      default:
        billingCycleObj = _.set({ ...billingCycleObj }, key, value)
        break;
    }

    setBillingCycle(billingCycleObj as IBillingCycle)
  }
  const handleOnSave = async () => {
    const confirm = await ConfirmDialog({
      dialogContentProps: {
        title: 'Confirm',
        closeButtonAriaLabel: 'Close',
        subText: `You will overwrite any manual changes for meters in this cycle!`
      },
      confirmBtnText: 'OK'
    })
    if (confirm) onSave(billingCycle as IBillingCycle)
  }


  return (
    <div className="edit-record">
      <div className="ms-Grid" dir="ltr">
        <section className='margin-top-md'>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-lg6">
              <TextField
                label="Period"
                placeholder="Period"
                onChange={(e, value) => handleOnChangeInput('period', value || '')}
                value={billingCycle?.period!} />
            </div>
            <div className="ms-Grid-col ms-lg6">
              <Dropdown
                label="Previous Period"
                selectedKey={billingCycle?.prevPeriod}
                onChange={(e, item) => handleOnChangeInput("prevPeriod", item?.key)}
                placeholder="Choose Previous Period"
                options={periods.filter(p => p.key !== 'all')}
                styles={dropdownStyles}
              />
            </div>
          </div>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-lg6 margin-top-xsm">
              <SystemPicker selectedItems={[{ key: 'all', name: 'All' }]} onChange={handleOnChangeInput} />
            </div>

            <div className="ms-Grid-col ms-lg6">
              <Dropdown
                label="Funder"
                placeholder="Select Funder (optional)"
                selectedKey={billingCycle?.funder || 'CBS'}
                onChange={(e, item) => handleOnChangeInput("funder", item?.key)}
                options={FundingOptions.filter(f => f.key !== 'all') as IDropdownOption[]}
                styles={dropdownStyles}
              />
            </div>
          </div>

          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-lg6">
              <DatePicker
                label="Start Date"
                placeholder="Select a date..."
                ariaLabel="Select a date"
                value={billingCycle?.start!}
                onSelectDate={(date) => handleOnChangeInput('start', new Date(Moment(date).startOf('day').format('YYYY-MM-DD')))}
                styles={dropdownStyles}
              />
            </div>
            <div className="ms-Grid-col ms-lg6">
              <DatePicker
                label="End Date"
                placeholder="Select a date..."
                ariaLabel="Select a date"
                value={billingCycle?.end!}
                onSelectDate={(date) => handleOnChangeInput('end', new Date(Moment(date).startOf('day').format('YYYY-MM-DD')))}
                styles={dropdownStyles}
              />
            </div>
          </div>
        </section>
      </div>
      <Sticky stickyPosition={StickyPositionType.Footer}>
        <Stack horizontal horizontalAlign="center">
          <Stack.Item className="margin-top-lg">
            {billingCycle?.prevPeriod && billingCycle.period && <PrimaryButton text="Select" onClick={handleOnSave} className='btn-primary' />}
            <DefaultButton onClick={onCancel} text="Cancel" />
          </Stack.Item>
        </Stack>
      </Sticky>
    </div>
  );
};

interface OwnProps {
  onCancel: () => void
  onSave: (billingCycle: IBillingCycle) => void
}

type Props = OwnProps;
