import { useState } from 'react';
import { CheckboxVisibility, ScrollablePane, ScrollbarVisibility } from '@fluentui/react';
import { SelectionMode, IColumn } from '@fluentui/react/lib/index';
import { useDispatch, useSelector } from "react-redux";
import { ISPUserState } from '../../../../store/admin/spUsers/reducer';
import { RootState } from '../../../../store';
import openUserModal, { OpenSPUserModal } from './spUserDialog';
import { getSPUserColumns } from './helper';
import { getSPUsers, setSPUserFilter } from '../../../../store/admin/spUsers/action';
import DetailsListWapper from '../../../common/DetailsListWapper';
import { DetailListStickyHeader } from '../../common/DetailListStickyHeader';
import Pagination from '../../common/Pagination';



const SPUserList = ({ actions }: Props = { actions: [] }) => {
  const dispatch = useDispatch();
  const { spUsers, totalCount, spUserFilter }: ISPUserState = useSelector<RootState, ISPUserState>((state: RootState) => state.web.spUser)



  const handleOnEdit = async (data: any) => {
    await OpenSPUserModal({ spUser: data })
  };



  const headerRow = getSPUserColumns({
    actions: [
      {
        icon: 'Edit',
        onClick: handleOnEdit,
      }
    ]
  })
  const [columns] = useState<IColumn[]>(headerRow)


  const handleOnSort = (field: string, order: string) => {
    const _field = field === 'name' ? 'userName' : "userEmail";
    const params = { ...spUserFilter, sortField: _field, sortOrder: order };
    dispatch(setSPUserFilter(params));
    dispatch(getSPUsers(params))
  }
  const loadNextPage = (p: any) => {
    dispatch(setSPUserFilter({ ...spUserFilter, ...p }))
    dispatch(getSPUsers())
  }

  return (
    <>
      {totalCount > 0 ? (
        <>
          <div className="data-list-container">
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              <DetailsListWapper
                items={spUsers}
                columns={columns}
                selectionMode={SelectionMode.none}
                onRenderDetailsHeader={DetailListStickyHeader}
                compact={true}
                checkboxVisibility={CheckboxVisibility.hidden}
                canLoadMore={spUsers.length < totalCount}
                // onRequestLoadMore={loadNextPage}
                onSort={(field, order) => handleOnSort(field, order)}
              />
            </ScrollablePane>
          </div>
          <Pagination
            totalRecords={totalCount}
            onPaginate={(page) => loadNextPage({ page })}
            currentPage={spUserFilter?.page!}
            pageSize={spUserFilter.pageSize!}
          />
        </>
      ) : (
        <p style={{ paddingLeft: "1rem", textAlign: "center" }}>
          No Data Found!
        </p>
      )}
    </>
  )
};

export default SPUserList;
interface OwnProps {
  actions: string[];
}

type Props = OwnProps;

