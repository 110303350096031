import { useEffect } from 'react'
import { useDispatch } from "react-redux";

import Loader from '../../common/Loader';
import { getAclPermissions } from '../../../../store/admin/aclPermission/action';
import AclPermissionFilters from './AclPermissionFilters';
import AclPermissionList from './AclPermissionList';

const AclPermsionScreen = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAclPermissions())
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        position: "relative",
      }}
    >
      <Loader />
      <header className="table-header">
        <h1>Acl Permissions</h1>
        <AclPermissionFilters /> 
      </header>
      <AclPermissionList actions={['add', 'edit', 'delete']} />
    </div>
  );
}
export default AclPermsionScreen;

