import { IAclRole, IAclRoleFilter } from "../../../components/admin/solarportal/aclRoles/types";
import { a } from "../../../components/admin/meterReading/common";
import { Action } from "./actionTypes";

// States' definition
export interface IAclRoleState {
  isLoading: boolean;
  aclRoles: IAclRole[];
  aclRoleFilter: IAclRoleFilter;
  totalCount: number;
}
export interface State {
  aclRoleState: IAclRoleState;
}

const aclRole = (
  state: IAclRoleState = {
    isLoading: false,
    aclRoles: [],
    aclRoleFilter: {
      page: 1,
      pageSize: 50,
      sortField: "_id",
      sortOrder: "desc",
    },
    totalCount: 0,
  },
  action: Action
): IAclRoleState => {
  switch (action.type) {
    case "SET_ACL_ROLE_FILTER":
      return { ...state, aclRoleFilter: action.aclRoleFilter };
    case "SET_ACL_ROLE_LIST":
      const aclRoles = action.reset
        ? action.aclRoles
        : [...state.aclRoles, ...action.aclRoles];
      return { ...state, aclRoles, totalCount: action.totalCount };
    case "DELETE_ACL_ROLE": {
      const stateObj = { ...state };
      const aclRoles: IAclRole[] = stateObj.aclRoles.filter(
        (s) => s._id !== action._id
      );
      const aclRoleFilter = { ...stateObj.aclRoleFilter };
      const { page, pageSize } = aclRoleFilter;
      return {
        ...state,
        aclRoles,
        totalCount: stateObj.totalCount,
        aclRoleFilter: {
          ...aclRoleFilter,
          page: Math.ceil((aclRoles.length / page!) * pageSize!),
        },
      };
    }
    case "UPDATE_ACL_ROLE": {
      const aclRoles = [...state.aclRoles];
      const index = aclRoles.findIndex(
        (s) => s._id === action.aclRole?._id
      );
      if (index > -1) {
        aclRoles[index] = action.aclRole;
      } else {
        aclRoles.unshift(action.aclRole);
      }
      return {
        ...state,
        aclRoles,
        totalCount: index > -1 ? state.totalCount : state.totalCount + 1,
      };
    }
    default:
      return state;
  }
};

export default aclRole;
