import { ComboBox, DefaultButton, PrimaryButton, Stack, Sticky, StickyPositionType, TextField } from "@fluentui/react"
import _ from "lodash"
import { useEffect, useState } from "react"
import ReactDOM from "react-dom"
import { Provider, useDispatch } from "react-redux"
import store from "../../../../store"
import ModalDialog from "../../common/ModalDialog"
import { validateSPUser } from "./helper"
import { ISPUser } from "./types"
import { toast } from "react-toastify"
import { IComboBoxOption } from "office-ui-fabric-react"
import { getAclRolesApi } from "../../../../store/client/solarportal/aclRole"
import { createUpdateSPUser } from "../../../../store/admin/spUsers/action"


export const OpenSPUserModal = (props?: any) => {
  return new Promise<ISPUser | undefined>((resolve, reject) => {
    const mountTarget = document.createElement('div')
    document.body.appendChild(mountTarget)
    const callback = (spUser?: ISPUser) => {
      resolve(spUser);
      ReactDOM.unmountComponentAtNode(mountTarget)
      mountTarget.remove()
    }
    ReactDOM.render(
      <Provider store={store}>
        <ModalDialog
          isModalOpen={true}
          title="Add/Edit User Role"
          onDismiss={() => callback(undefined)}
          containerClassName={"modal-size-sm"}
        >
          <EditspUserDialog
            {...props}
            onSave={(spUser: ISPUser | undefined) => callback(spUser)}
            onCancel={() => callback(undefined)}
          />
        </ModalDialog>
      </Provider>,
      mountTarget
    )
  })
}

const EditspUserDialog = (props: ISPUserProps) => {
  const dispatch = useDispatch();

  const [spUser, setspUser] = useState<ISPUser>(_.cloneDeep(props.spUser as ISPUser))
  const [errors, setErrors] = useState<any>({});
  const [roles, setRoles] = useState<any[]>([]);
  const [payload, setPayload] = useState<any>({
    _id: spUser?._id,
    roles: spUser?.roles?.map((r: any) => r._id.toString()) || []
  });

  const fetchAclRoles = async () => {
    try {
      const { data } = await getAclRolesApi({ sortBy: 'name', orderBy: "asc", all: true })
      setRoles(data.map((d: any) => ({ key: d._id, text: d.name })));
    } catch (error) {
      console.log(error)
      toast.error("Failed to fetch roles");
    }

  };
  useEffect(() => {
    fetchAclRoles();
  }, []);


  const handleOnSave = async () => {

    const errors = await validateSPUser(payload);
    const data = { ...payload }
    const _spUser = { ...spUser, roles: roles.filter((r: any) => data.roles.includes(r.key)) }
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }
    await dispatch(createUpdateSPUser(data, spUser))
    props.onSave(spUser)
  }


  const handleOnChangeInput = (key: string, value: any) => {
    switch (key) {
      default:
        setPayload(_.set({ ...payload }, key, value))
        break;
    }
  }
  return (
    <div className="edit-record">
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <TextField
            label="User Name"
            value={spUser.name}
            readOnly  // This is readonly
            className="ms-Grid-col ms-lg4"
            errorMessage={errors.userName} />

          <TextField
            label="User Name"
            value={spUser.email}
            readOnly  // This is readonly
            className="ms-Grid-col ms-lg4"
            errorMessage={errors.email} />
        </div>
        <div className="ms-Grid-row">

          <ComboBox
            label="Select Roles"
            multiSelect
            selectedKey={payload.roles}
            placeholder="Type to filter"
            allowFreeform={true}
            autoComplete={"on"}
            options={roles as IComboBoxOption[]}
            className="ms-Grid-col ms-lg12"
            errorMessage={errors.roles}
            onChange={(e, item) => {
              const selectedKeys = item!.selected
                ? [...payload.roles, item!.key]
                : payload.roles.filter((key: any) => key !== item!.key);
              handleOnChangeInput("roles", selectedKeys);
            }}
          />
        </div>

      </div>
      <Sticky stickyPosition={StickyPositionType.Footer}>
        <Stack horizontal horizontalAlign="center">
          <Stack.Item>
            <PrimaryButton
              text="Save"
              onClick={handleOnSave}
              className="btn-primary"
            />
            <DefaultButton onClick={props.onCancel} text="Cancel" />
          </Stack.Item>
        </Stack>
      </Sticky>
    </div>
  );
};

interface ISPUserProps {
  onCancel?: () => void;
  onSave: (spUser: ISPUser) => void;
  spUser?: ISPUser;
}

export default OpenSPUserModal;
