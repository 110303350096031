import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import store, { RootState } from "./store/";
import ContentHome from "./components/learning/Content/ContentHome";
import "./App.css";
import "./css/Style.css";
import LoginScreen from "./components/login/LoginScreen";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import setAuthToken from "./utils/setAuthToken";
import PrivateRoute from "./utils/PrivateRoute";
import { setUserDetails } from "./store/admin/action";
import AppMenu from "./components/admin/AppMenu";
import Dashboard from "./components/admin/Dashboard";
import Systems from "./components/admin/system";
import Billing from "./components/admin/billing";
import Glossary from "./components/learning/Content/glossary";
import Invoice from "./components/admin/invoice";
import InvoicePrice from "./components/admin/invoicePrices";
import Schools from "./components/admin/schools";
import Products from "./components/admin/product";
import Projects from "./components/admin/project";
import Opportunities from "./components/admin/opportunity";
import BillingPeriod from "./components/admin/period";
import Tasks from "./components/admin/task";
import Workflow from "./components/admin/workflow";
import TaskType from "./components/admin/taskType";
import Log from "./components/admin/Log";
import UserFunction from "./components/admin/userFunction";
import UserSkill from "./components/admin/userSkill";
import AclRole from "./components/admin/solarportal/aclRoles";
import SPUser from "./components/admin/solarportal/spUsers";
import AclAccess from "./components/admin/solarportal/aclAccesses";
import AclPermission from "./components/admin/solarportal/aclPermissions";
import Blog from "./components/admin/blog";
import Yields from "./components/admin/yields";
import MeterReading from "./components/admin/meterReading";
import { SocketContext, socket } from "./context/socket";
import RTYields from "./components/admin/rtYields";
import User from "./components/admin/user";
import CoreLogs from "./components/admin/CoreLogs";
import WebsiteLogs from "./components/admin/WebsiteLogs";
import { IAdmin } from "./store/admin/reducer";
import { IWebUser } from "./store/admin/actionTypes";
import Faqs from "./components/admin/faqs";
import Account from "./components/admin/account";
import BondHolder from "./components/admin/funder/bondholder";
import Bond from "./components/admin/funder/bond";
import Trnsaction from "./components/admin/funder/transaction";
import BondOffer from "./components/admin/funder/bondOffer";
import Certificate from "./components/admin/funder/certificate";
import Configuration from "./components/admin/funder/configuration";
import School from "./components/admin/reporting/school/school";
import InvoiceReporting from "./components/admin/reporting/invoice";
import FitReport from "./components/admin/fitReporting";
import RegionConfigs from "./components/admin/settings";
import Portfolio from "./components/admin/funder/portfolio";
import EnvConfig from "./components/admin/envConfig";
import OtherConfig from "./components/admin/OtherConfig";
import ScriptsSettings from "./components/admin/scriptsSettings";
import OneSkySync from "./components/admin/oneSkySync";
import Comments from "./components/admin/comments";
// import LiveMeterDumps from "./components/admin/liveMeterDumps";
import LiveMeterDumps from "./components/admin/TSReading";
import CostSummary from "./components/admin/costSummary";
import GSSReporting from "./components/admin/reporting/gssReporting";
import GenerateReport from "./components/admin/generateReport";
import DataSet from "./components/admin/dataset";
import InvoiceAccountReporting from "./components/admin/reporting/invoiceAccountReporting";
import Translations from "./components/admin/translations";
import Team from "./components/admin/team";
import ActivityLogger from "./components/admin/activityLogger";
import Supporters from "./components/admin/supporters";
import DataFeed from "./components/admin/reporting/datafeed";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

function App() {
  const admin: IAdmin = useSelector<RootState, IAdmin>(
    (state: RootState) => state.web.admin
  );

  const { permission, funderAdmin } = admin?.user || ({} as IWebUser);
  useEffect(() => {
    store.dispatch(setUserDetails());
  }, []);

  return (
    <div className="App">
      <SocketContext.Provider value={socket}>
        <Router>
          <ToastContainer
            style={{ zIndex: 9999999 }}
            position="bottom-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          {/* <Log /> */}
          <Switch>
            <Route exact path="/" component={LoginScreen} />
            <Route exact path="/login" component={LoginScreen} />
            <div className="home-container">
              <AppMenu />
              <div className="work-area-container">
                <PrivateRoute
                  exact
                  path="/content/glossary"
                  component={Glossary}
                />
                <PrivateRoute exact path="/content" component={ContentHome} />
                <PrivateRoute exact path="/home" component={Dashboard} />
                <PrivateRoute exact path="/systems" component={Systems} />
                <PrivateRoute exact path="/systems/:id" component={Systems} />
                <PrivateRoute exact path="/yields" component={Yields} />
                <PrivateRoute exact path="/rt-yields" component={RTYields} />
                <PrivateRoute exact path="/meters" component={MeterReading} />
                <PrivateRoute
                  exact
                  path="/livedumps"
                  component={LiveMeterDumps}
                />
                <PrivateRoute exact path="/schools" component={Schools} />
                <PrivateRoute exact path="/schools/:slug" component={Schools} />
                <PrivateRoute exact path="/comments" component={Comments} />
                <PrivateRoute
                  exact
                  path="/cost/summary"
                  component={CostSummary}
                />
                <PrivateRoute exact path="/products" component={Products} />
                <PrivateRoute
                  exact
                  path="/opportunities"
                  component={Opportunities}
                />
                <PrivateRoute
                  exact
                  path="/opportunities/:type/:linkedto"
                  component={Opportunities}
                />
                <PrivateRoute
                  exact
                  path="/billing/readings"
                  component={Billing}
                />
                <PrivateRoute
                  exact
                  path="/billing/invoices"
                  component={Invoice}
                />
                <PrivateRoute
                  exact
                  path="/billing/periods"
                  component={BillingPeriod}
                />
                <PrivateRoute
                  exact
                  path="/billing/prices"
                  component={InvoicePrice}
                />
                <PrivateRoute exact path="/projects" component={Projects} />
                <PrivateRoute
                  exact
                  path="/projects/:slug"
                  component={Projects}
                />
                <PrivateRoute exact path="/workflows" component={Workflow} />
                <PrivateRoute exact path="/tasks" component={Tasks} />
                <PrivateRoute exact path="/tasktypes" component={TaskType} />
                <PrivateRoute
                  exact
                  path="/user-functions"
                  component={UserFunction}
                />
                <PrivateRoute exact path="/acl-roles" component={AclRole} />
                <PrivateRoute exact path="/sp-users" component={SPUser} />
                <PrivateRoute exact path="/acl-accesses" component={AclAccess} />
                <PrivateRoute exact path="/acl-permissions" component={AclPermission} />
                <PrivateRoute exact path="/user-skills" component={UserSkill} />
                <PrivateRoute exact path="/blogs" component={Blog} />

                {permission?.includes("itDev") && (
                  <>
                    <PrivateRoute
                      exact
                      path="/core-logs"
                      component={CoreLogs}
                    />
                    <PrivateRoute
                      exact
                      path="/website-logs"
                      component={WebsiteLogs}
                    />
                    <PrivateRoute
                      exact
                      path="/sfs/translations"
                      component={OneSkySync}
                    />
                  </>
                )}
                {
                  permission?.includes('translation') && (
                    <PrivateRoute
                      exact
                      path="/translations"
                      component={Translations}
                    />
                  )
                }

                {
                  permission?.includes('translation') && permission?.includes('translationApprover') && (
                    <PrivateRoute
                      exact
                      path="/translations/pending"
                      component={Translations}
                    />
                  )
                }

                {permission?.includes("userAdmin") && (
                  <PrivateRoute exact path="/users" component={User} />
                )}

                {permission?.includes("team") && (
                  <PrivateRoute exact path="/team" component={Team} />
                )}
                {funderAdmin && (
                  <>
                    <PrivateRoute
                      exact
                      path="/funder/bond-holders"
                      component={BondHolder}
                    />
                    <PrivateRoute
                      exact
                      path="/funder/bond-holders/:email"
                      component={BondHolder}
                    />
                    <PrivateRoute exact path="/funder/bonds" component={Bond} />
                    <PrivateRoute
                      exact
                      path="/funder/bonds/:cert"
                      component={Bond}
                    />

                    <PrivateRoute
                      exact
                      path="/funder/bond-offers"
                      component={BondOffer}
                    />
                    <PrivateRoute
                      exact
                      path="/funder/bond-offers/:id"
                      component={BondOffer}
                    />

                    <PrivateRoute
                      exact
                      path="/funder/bond-transactions"
                      component={Trnsaction}
                    />
                    <PrivateRoute
                      exact
                      path="/funder/bond-transactions/:id"
                      component={Trnsaction}
                    />
                    <PrivateRoute
                      exact
                      path="/funder/certificates"
                      component={Certificate}
                    />
                    <PrivateRoute
                      exact
                      path="/funder/portfolio"
                      component={Portfolio}
                    />

                    <PrivateRoute
                      exact
                      path="/funder/config"
                      component={Configuration}
                    />
                  </>
                )}
                <PrivateRoute exact path="/faqs" component={Faqs} />
                <PrivateRoute exact path="/report/School" component={School} />
                <PrivateRoute exact path="/report/datafeed" component={DataFeed} />
                <PrivateRoute exact path="/report/invoice" component={InvoiceReporting} />
                <PrivateRoute exact path="/report/gss-report" component={GSSReporting} />
                <PrivateRoute exact path="/report/invoice-account-report" component={InvoiceAccountReporting} />
                <PrivateRoute
                  exact
                  path="/report/fit-report"
                  component={FitReport}
                />
                <PrivateRoute exact path="/generate/report" component={GenerateReport} />
                <PrivateRoute exact path="/dataset" component={DataSet} />
                <PrivateRoute exact path="/activity" component={ActivityLogger} />
                <PrivateRoute exact path="/supporters" component={Supporters} />
                {permission?.includes("settings") && (
                  <>
                    <PrivateRoute
                      exact
                      path="/settings/config"
                      component={RegionConfigs}
                    />
                    <PrivateRoute
                      exact
                      path="/settings/config/:slug"
                      component={RegionConfigs}
                    />
                    <PrivateRoute
                      exact
                      path="/settings/env/config"
                      component={EnvConfig}
                    />
                    <PrivateRoute
                      exact
                      path="/settings/other/config"
                      component={OtherConfig}
                    />
                    <PrivateRoute
                      exact
                      path="/settings/scripts"
                      component={ScriptsSettings}
                    />
                    <PrivateRoute
                      exact
                      path="/settings/scripts/:scriptName"
                      component={ScriptsSettings}
                    />

                  </>
                )}
                <PrivateRoute exact path="/account" component={Account} />
              </div>
            </div>
          </Switch>
        </Router>
      </SocketContext.Provider>
    </div>
  );
}

export default App;
