import { FontIcon, IColumn, IDropdownOption, TooltipHost } from "@fluentui/react";
import { IField } from "../../../dynamic-form/DynamicForm";
import { StringMap } from "../../common/types";
import { isSortable } from "../../common/utils";
import { IAclPermission } from "./types";
import { AclPermissionTypes, requiredMessage } from "../../common/common";

export const getGeneralFields = (options: StringMap): IField[] => {
    // console.log('options', options.payload)
    const fields: IField[] = [
        {
            label: "Type",
            key: "type",
            type: "dropdown",
            selectedKey: options.payload?.type || "none",
            extras: { required: true },
            options: AclPermissionTypes.filter(d => d.key !== "all") as IDropdownOption[],
            className: 'ms-Grid-col ms-lg3'
        }
    ];
    return fields;
}
export const validateAclPermission = async (params: any) => {
    let errors: StringMap = {};
    const { name, type } = params || {}
    if (!name) errors['name'] = requiredMessage;
    if (type === "school" && !params.schools) errors['schools'] = requiredMessage;
    if (type === "region" && !params.regionAccesses?.length) errors['regionAccess'] = requiredMessage;
    if (type !== "region" && type !== "school" && !params.accesses?.length) errors['accesses'] = requiredMessage;

    // if (!country) errors['country'] = requiredMessage;
    // if (!Object.keys(errors).length) {
    //     errors = await validateUserFunctionApi(params) as unknown as StringMap
    // }
    return errors;
}


export const getAclPermissionColumns = (params: {
    actions: {
        icon: string,
        onClick: (task: any) => any
    }[]
}): IColumn[] => {
    return [
        {
            key: 'name',
            name: 'Name',
            fieldName: 'name',
            minWidth: 120,
            maxWidth: 150,
            ...isSortable,
            onRender: (item) => <TooltipHost content={item.name} closeDelay={500}> 
            
            <span style={item?.isDeleted ? { color: 'red' } : {}}>{item.name}</span></TooltipHost>
        },
        {
            name: 'Description',
            key: 'description',
            fieldName: 'description',
            minWidth: 200,
            maxWidth: 600,
            ...isSortable,
            onRender: (item: any) => <TooltipHost content={item.description || ''} closeDelay={500}>
                
                <span style={item?.isDeleted ? { color: 'red' } : {}}>{item?.description}</span></TooltipHost>
        },
        {
            name: 'Type',
            key: 'type',
            fieldName: 'type',
            minWidth: 75,
            maxWidth: 75,
            ...isSortable,
            onRender: (item: any) => <TooltipHost content={item.type || ''} closeDelay={500}>
                <span style={item?.isDeleted ? { color: 'red' } : {}}> {item.type}</span></TooltipHost>
        },
        {
            key: 'active',
            name: 'Active',
            fieldName: 'isDeleted',
            minWidth: 75,
            maxWidth: 75,
            ...isSortable,
            onRender: (item: any) => <TooltipHost content={item.isDeleted ? 'No' : 'Yes'} closeDelay={500}>
                <span style={item?.isDeleted ? { color: 'red' } : {}}>  {item.isDeleted ? 'No' : 'Yes'}</span></TooltipHost>
        },
        {
            key: 'action',
            name: 'Action',
            fieldName: '-',
            ariaLabel: "Action",
            minWidth: 150,
            maxWidth: 300,
            onRender: (taskType) => {
                return (
                    <div className="action-icons-container">
                        {params.actions.map((action: { icon: string | undefined; onClick: (arg0: any) => void; }) => {
                            return <FontIcon
                                iconName={action.icon}
                                onClick={() => action.onClick(taskType)} />;
                        })}
                    </div>
                );
            },
        }
    ];
}

export const initAclPermission: IAclPermission = {
    name: '',
    type: 'feature',
    isDeleted: false,
} as unknown as IAclPermission
