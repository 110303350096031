
import { IEstablishment, ISystem } from '@solarforschools/sfs-core';
import { IPVAMSystem } from '@solarforschools/sfs-core/dist/PVAM/types';
import { IMeter } from '@solarforschools/sfs-core/dist/solardb/types';
import MomentTz from 'moment-timezone';
import { getSchoolBySlug } from '../../../store/admin/school/action';
import { IAddSystemParams } from '../common/types';
import { IPvamSite, IPvamSiteMeter } from "./types";




export const TimeZones = () => {
  const timeZones = MomentTz.tz.names();
  const offsetTmz = []
  for (const tz of timeZones) {
    offsetTmz.push({ key: tz, text: tz })
  }
  return offsetTmz
};

export const Providers = [
  { key: 'all', text: 'All' },
  { key: 'solaredge', text: 'Solaredge' },
  { key: 'engynious', text: 'Engynious' },
  { key: 'huawei', text: 'Huawei' },
  { key: 'orsis', text: 'Orsis' },
  { key: 'youdera', text: 'Youdera' },
  { key: 'SfS', text: 'SFS' },
  { key: 'virtual', text: 'Virtual' },
  { key: 'SMA', text: 'SMA' },
  { key: 'solis', text: 'Solis' },
];

export const DeviceTypes = [
  { key: 'meter', text: 'meter' },
  { key: 'inverter', text: 'inverter' }
]

export const MeterTypes = [
  { key: "INV", text: "INV" },
  { key: "B", text: "B" },
  { key: "G", text: "G" },
  { key: "E", text: "E" },
  { key: "I", text: "I" },
  { key: "C", text: "C" },
  { key: "EV", text: "EV" },
  { key: "SC", text: "SC" },
]

export const Meters = ['G', 'E', 'I', 'C', 'SC', 'INV', "EV", 'B']

export const BooleanValues = [
  { key: 'false', text: 'false' },
  { key: 'true', text: 'true' }
]

export const DefaultMeter = {
  mid: null,
  deviceType: "meter",
  type: "G",
  MSN: '',
  factor: 1,
  active: false,
  include: false,
  dataFormat: '',
  folderName: '',
  channel: '',
  peakPower: null
}

export const DataFormats = [
  { key: '', text: 'Select' },
  { key: 'ALGODUE', text: 'ALGODUE' },
  { key: 'RASPI', text: 'RASPI' },
  { key: 'SOLARLOG', text: 'SOLARLOG' },
  { key: 'STARK', text: 'STARK' },
  { key: 'LIVE_DUMP', text: 'LIVE_DB' }
]

const pvamMeterType: { [char: string]: string } = {
  Generation: 'G',
  Import: 'I',
  Export: 'E',
  'Export/Import': 'E/I'
}

const convertType = (type: string) => {
  const types: any = {
    Production: 'G',
    Purchased: 'I',
    FeedIn: 'E',
    Consumption: 'C',
    SelfConsumption: 'SC',
    EV: 'EV',
    B: 'B'
  }
  return types[type as string] || type
}

const getFactor = (systemId: number, type: string) => {
  if (systemId === 763861 && ['Purchased', 'FeedIn', 'I', 'E'].includes(type)) { return 1 / 3 }
  return 1
}

const initSEMeter = (system: any, data: any) => {
  const meters: IMeter[] = [] as IMeter[];
  // loop over meters
  if (data?.Inventory?.meters) {
    for (const meter of data.Inventory.meters) {
      meters.push({
        channelNumber: '',
        // set serial for virtual meters to -1
        // if meter connected to Inverter then use connectedSolaredgeDeviceSN
        mid: (meter.form === 'virtual') ? -1 : (meter.SN ? meter.SN : meter.connectedSolaredgeDeviceSN),
        MSN: '',
        type: convertType(meter.type),
        deviceType: 'meter',
        factor: getFactor(system.id, meter.type),
        active: true,
        include: true,
        peakPower: undefined,
        channel: undefined,
        dataFormat: undefined,
        folderName: undefined,
        FiT: false,
      })
    }
  }

  // loop over inverters
  if (data.Inventory && data.Inventory.inverters) {
    for (const inverter of data.Inventory.inverters) {
      meters.push({
        channelNumber: '',
        mid: inverter.SN,
        MSN: '',
        type: 'G',
        deviceType: 'inverter',
        factor: getFactor(system.id, 'G'),
        active: false,
        include: false,
        peakPower: undefined,
        channel: undefined,
        dataFormat: undefined,
        folderName: undefined,
        FiT: false,
      })
    }
  }
  return meters
}
const initMeters = (meters: IPvamSiteMeter[]) => {
  const systemMeters: IMeter[] = []
  let i = 1
  const mapMeter = (meter: IPvamSiteMeter, index: number) => {
    return {
      mid: parseInt(meter.siteId) * 1000 + index,
      MSN: meter.MSN,
      type: meter.type,
      deviceType: 'meter',
      factor: meter.factor || 1,
      active: false,
      include: false,
      peakPower: meter.kWp,
      channel: '',
      dataFormat: meter.manufacturer?.toUpperCase(),
      folderName: '',
      FiT: false
    }
  }
  let meter: IMeter
  for (const m of meters) {
    if (Object.keys(pvamMeterType).includes(m.type)) {
      if (m.type === 'Export/Import') {
        meter = mapMeter({ ...m, type: 'I' }, i) as IMeter
        systemMeters.push(meter)
        i++
        meter = mapMeter({ ...m, type: 'E' }, i) as IMeter
        systemMeters.push(meter)
      } else {
        meter = mapMeter({ ...m, type: pvamMeterType[m.type] }, i) as IMeter
        systemMeters.push(meter)
      }
      i++
    }
  }
  return systemMeters
}

export const setTargetMonthlyYield = (epm?: any[]) => {
  let yieldPerMonth = {
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
  }
  if (epm) {
    let i = 0
    for (const key of Object.keys({ ...yieldPerMonth })) {
      yieldPerMonth = { ...yieldPerMonth, [key]: epm[i] || null }
      i++
    }
  }
  return yieldPerMonth
}

export const getSystemProps = (addSystemParams: IAddSystemParams, data: any): ISystem => {
  const { provider, pvamSite, pvamSESystem, seParams } = addSystemParams || {}
  let system: any = {
    _id: 'new',
    id: "",
    systemId: "",
    active: false,
    include: false,
    billedBy: 'school',
    panels: 0,
    dataStart: new Date(),
    installDate: new Date(),
    meters: [],
    name: '',
    ownedBy: 'school',
    peakPower: null,
    pricePaid: null,
    priceSolar: null,
    provider: 'engynious',
    schools: [],
    showMeters: {
      G: false,
      I: false,
      E: false,
      C: false,
      SC: false,
      EV: false,
      B: false
    },
    target: {
      yieldPerMonth: setTargetMonthlyYield(),
      yield: null,
      SC: null
    },
    virtual: false,
    country: '',
    invUrl: '',
    APIKey: '',
    TZ: '',
    MPAN: {
      import: '',
      export: ''
    },
    siteId: 0,
    contractId: ''
  }
  if (pvamSite) {
    const { siteId, installDate, annualYield, selfConsumption, exportMPAN, importMPAN } = pvamSite || {}
    system = {
      ...system,
      id: siteId,
      billedBy: pvamSite.billedBy || 'school',
      peakPower: parseFloat(pvamSite.systemSize || '0'),
      siteId: siteId,
      priceSolar: (pvamSite.ppaPrice / 100 || 0),
      pricePaid: (pvamSite.mainsPrice / 100 || 0),
      priceExport: (pvamSite.exportPrice / 100 || 0),
      name: pvamSite.site || pvamSite.siteName,
      country: pvamSite.country,
      ownedBy: pvamSite.ownedBy || 'school',
      installDate: installDate ? new Date(installDate) : new Date(),
      dataStart: installDate ? new Date(installDate) : new Date(),
      target: { ...system.target, yield: annualYield, SC: selfConsumption ? selfConsumption / 100 : null },
      MPAN: {
        import: importMPAN,
        export: exportMPAN
      },
      contractId: pvamSite.contractId || ''
    }
  }

  if (provider === 'solaredge') {
    if (pvamSESystem) {
      const {
        name,
        systemId,
        siteId,
        contractId,
        country,
        provider,
        dataStart,
        peakPower,
        targetYield,
        targetSC,
        apiKey } = pvamSESystem
      system = {
        ...system,
        name,
        id: systemId,
        systemId,
        siteId,
        contractId,
        country,
        provider,
        APIKey: apiKey || seParams?.apiKey,
        target: { ...system.target, yeild: targetYield, SC: targetSC ? targetSC / 100 : null },
        peakPower: parseFloat(peakPower || '0'),
        dataStart: dataStart ? new Date(dataStart) : new Date(),
        installDate: dataStart ? new Date(dataStart) : new Date()
      }
    } else if (data.seSystem) {
      const { id, name, peakPower, installationDate, APIKey, status, location } = data?.seSystem?.details || {}
      system = {
        ...system,
        id: id,
        systemId: id,
        name,
        provider: 'solaredge',
        peakPower: parseFloat(peakPower || '0'),
        installDate: installationDate ? new Date(installationDate) : null,
        dataStart: installationDate ? new Date(installationDate) : null,
        active: status === 'Active',
        APIKey: APIKey || seParams?.apiKey,
        TZ: location?.timeZone,
        country: location?.countryCode
      }
    }
  }

  if (system.peakPower && !system.panels) system.panels = Math.floor(system.peakPower / 0.350);
  system.id = parseInt(system.id.toString())
  system.systemId = parseInt(system.id.toString())
  return system
}

const setShowMeterConfig = (meters: IMeter[]) => {
  // check meter existance
  const isGExist = meters.find((m) => m.type === 'G') ? true : false
  const isEExist = meters.find((m) => m.type === 'E') ? true : false
  const isIExist = meters.find((m) => m.type === 'I') ? true : false
  const isEVExist = meters.find((m) => m.type === 'EV') ? true : false
  const isBExist = meters.find((m) => m.type === 'B') ? true : false
  return {
    G: isGExist, E: isEExist, I: isIExist,
    C: isGExist && isIExist && isEExist,
    SC: isGExist && isIExist && isEExist,
    EV: isEVExist,
    B: isBExist
  }
}


const getSchool = async (pvamSite?: IPvamSite, pvamSESystem?: IPVAMSystem): Promise<IEstablishment | undefined | null> => {
  let slug: string | null | undefined = null
  slug = pvamSite?.slug || pvamSESystem?.slug
  if (!slug) return slug as unknown as null
  const school: IEstablishment = await getSchoolBySlug(slug);
  return school
}

export const getCountryTz = (country: string) => {
  // get time on base of country
  const tZs = MomentTz.tz.zonesForCountry(country) || [];
  return tZs[0] || null
}

export const initSystem = async (addSystemParams: IAddSystemParams, data: any): Promise<ISystem> => {
  const { provider, pvamSESystem, pvamSite } = addSystemParams || {}
  const system = getSystemProps(addSystemParams, data)
  const { epm, meters, panels } = data
  const seSystem = pvamSESystem || data.seSystem

  system.meters = provider === 'solaredge' ? initSEMeter(seSystem, meters) : initMeters(meters)

  system.showMeters = setShowMeterConfig(system.meters)

  if (epm) {
    system.target.yieldPerMonth = setTargetMonthlyYield(epm);
  }
  if (panels) {
    if (!system.panels) system.panels = panels.map((item: { NumberOfModules: any; }) => item.NumberOfModules).reduce((prev: any, next: any) => prev + next, 0);
    if (!system.peakPower) system.peakPower = panels.map((item: { ModulePower: any; }) => item.ModulePower).reduce((prev: any, next: any) => prev + next, 0);
  }

  // get time on base of country
  const school = await getSchool(pvamSite, pvamSESystem);
  if (school) {
    system.schools = school ? [{ schoolId: school._id, name: school.name, slug: school.slug }] : []
    if (school.yield && !system.target.yield) system.target.yield = school.yield;
    // energy per month :epm
    if (school.address?.country) {
      // get time on base of country
      const tZ = getCountryTz(school.address?.country)
      if (tZ) system.TZ = tZ
    }
  }
  if (!system.country) {
    system.country = "GB"
  }
  if (!system.TZ) {
    system.TZ = getCountryTz("GB") as string
  }

  return system as unknown as ISystem
}
