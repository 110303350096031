import axios from "axios";
import { ParsedUrlQueryInput } from "querystring";
import { IMeterReading } from "@solarforschools/sfs-core/dist/solardb/types";
import { generateQueryString } from "../../utils/util";
import { host, solaportaApiHost } from "../config";
import { ILiveTimeSeries } from "@solarforschools/sfs-core/dist/solardb/types";
import moment from "moment";


let GetMSNAPiAbortController: AbortController
let GetTSReadingAPiAbortController: AbortController

export const getTSMSNAPI = (query?: any): Promise<any> => {
  if (GetMSNAPiAbortController) GetMSNAPiAbortController.abort()
  GetMSNAPiAbortController = new AbortController();
  return axios.get(`${host}/ts-reading/msns?${generateQueryString(query as ParsedUrlQueryInput)}`, { signal: GetMSNAPiAbortController.signal }).then(res => res.data)
}
export const getTSReadingsApi = (query: any): Promise<any> => {
  if (GetTSReadingAPiAbortController) GetTSReadingAPiAbortController.abort()
  GetTSReadingAPiAbortController = new AbortController();
  return axios.get(`${host}/ts-reading?${generateQueryString(query as ParsedUrlQueryInput)}`, { signal: GetTSReadingAPiAbortController.signal }).then(res => res.data)
}
export const createMeterReadingAPi = (data: IMeterReading): Promise<any> => {
  return axios
    .post(`${host}/ts-reading`, data)
    .then((res) => res.data);
};

export const updateTSReadingApi = (data: ILiveTimeSeries): Promise<any> => {
  return axios
    .put(`${host}/ts-reading/${data._id}`, data)
    .then((res) => res.data);
};

// export const swapIAndETSReadingsApi = (payload: any): Promise<any> => {
//   return axios
//     .put(`${host}/ts-reading/swap-import-export`, payload)
//     .then((res) => res.data);
// };
export const swapIAndETSReadingsApi = (payload: any): Promise<any> => {
  const { start, startTime, end, endTime } = payload;
  // Combine date and time, and ensure UTC conversion
  const startISO = moment.utc(`${start} ${startTime}`, "YYYY-MM-DD HH:mm").toISOString();
  const endISO = moment.utc(`${end} ${endTime}`, "YYYY-MM-DD HH:mm").toISOString();
  const data = { msn: payload.msn, startTime: startISO, endTime: endISO }
  return axios
    .put(`${solaportaApiHost}/v1/swap-import-export`, data)
    .then((res) => res.data);
};


export const exportTSReadingApi = (query: any): Promise<any> => {
  const queryStr = generateQueryString(query as ParsedUrlQueryInput)
  return axios.get(`${host}/ts-reading/export?${queryStr}`, { responseType: 'arraybuffer' }).then(res => res.data)
}
