import { combineReducers } from "redux";
import { Action, IAuthEvent, IWebUser } from "./actionTypes";
import { toast } from "react-toastify";
import report, { IReport } from "./report/reducer";
import system, { ISystemState } from "./system/reducer";
import billing, { IBilling } from "./billing/reducer";
import invoice, { IInvoice } from "./invoice/reducer";
import school, { ISchool } from "./school/reducer";
import product, { IProduct } from "./product/reducer";
import opportunity, { IOpportunity } from "./opportunity/reducer";
import project, { IProjectState } from "./projects/reducer";
import billingPeriod, { IBillingPeriodState } from "./period/reducer";
import taskType, { ITaskTypeState } from "./taskType/reducer";
import task, { ITaskState } from "./task/reducer";
import projectWorkflow, { IWorkflowState } from "./workflow/reducer";
import userFunction, { IUserFunctionState } from "./userFunction/reducer";
import userSkill, { IUserSkillState } from "./userSkill/reducer";
import blogs, { IBlogs } from "./blog/reducers";
import yields, { IYieldsState } from "./yields/reducer";
import meterReading, { IMeterReadingState } from "./meterReading/reducer";
import rtYields, { IRTYieldState } from "./rtYields/reducer";
import user, { IUserState } from "./users/reducer";
import faqs, { IFaqState } from "./faqs/reducer";
import bondHolder, { IBondHolderState } from "./funder/bondHolder/reducer";
import bonds, { IBondState } from "./funder/bond/reducer";
import bondOffer, { IBondOfferState } from "./funder/bondOffer/reducer";
import transaction, {
  IBondTransationState,
} from "./funder/transaction/reducer";
import certificates, { ICertificateState } from "./funder/certificate/reducer";
import reports, { IReports } from "./reporting/reports/reducer";
import fitReport, { IFitReportingState } from "./fitReporting/reducer";
import regionConfig, { IRegionConfigState } from "./settings/reducer";
import envConfig, { IEnvConfigState } from "./envConfig/reducer";
import portfolio, { IPortfolioState } from "./funder/portfolio/reducer";
import otherConfig, { IOtherConfigState } from "./otherConfig/reducer";
import adminDashboardData, {
  IAdminDashboardDataState,
} from "./adminDashboard/reducers";
import liveMeterDumps, { ILiveMeterDumpsState } from "./LiveDumps/reducer";
import comments, { IComments } from "./Comments/reducer";
import costSummary, { ICostSummary } from "./costSummary/reducers";
import generateReport, { IGenerateReport } from "./generateReport/reducers";
import invoiceReport, { IInvoiceReport } from "./reporting/invoice/reducer";
import gssReport, { IGSSReport } from "./reporting/gssReport/reducer";
import invoiceAccountReport, {
  IInvoiceAccountReport,
} from "./reporting/invoiceAccountReport/reducer";
import translations from "./translations/reducer";
import { ITranslations } from "../../components/admin/translations/utils/types";
import team, { ITeam } from "./team/reducer";
import activityLogger, { IActLogInitial } from "./activityLogger/reducers";
import invoicePrice, { IInvoicePrice } from "./invoicePrice/reducer";
import supporters, { IInitialState as ISupporters } from "./supporters/reducer";
import tsReadings, { ITSReadingState } from "./tsReading/reducer";
import datafeed, { IDatafeed } from "./reporting/datafeed/reducer";
import aclAccess, { IAclAccessState } from "./aclAccess/reducer";
import { IAclPermission } from "../../components/admin/solarportal/aclPermissions/types";
import aclPermission, { IAclPermissionState } from "./aclPermission/reducer";
import aclRole, { IAclRoleState } from "./aclRole/reducer";
import spUser, { ISPUserState } from "./spUsers/reducer";
// States' definition
export interface IAdmin {
  isLoading: boolean;
  isLoadingData: boolean;
  user?: IWebUser | null | undefined;
  token?: string | null;
  isAuthenticated?: boolean;
  authEvent?: IAuthEvent;
  redirectTo?: string;
}
export interface State {
  userFunction: IUserFunctionState;
  regionConfig: IRegionConfigState;
  translations: ITranslations;
  activityLogger: IActLogInitial;
  envConfig: IEnvConfigState | undefined;
  billingPeriod: IBillingPeriodState;
  admin: IAdmin;
  team: ITeam;
  report: IReport;
  invoiceReport: IInvoiceReport;
  gssReport: IGSSReport;
  system: ISystemState;
  billing: IBilling;
  invoice: IInvoice;
  invoicePrice: IInvoicePrice;
  school: ISchool;
  product: IProduct;
  opportunity: IOpportunity;
  project: IProjectState;
  projectWorkflow: IWorkflowState;
  task: ITaskState;
  taskType: ITaskTypeState;
  userSkill: IUserSkillState;
  blogs: IBlogs;
  yields: IYieldsState;
  meterReading: IMeterReadingState;
  rtYields: IRTYieldState;
  user: IUserState;
  faqs: IFaqState;
  bondHolder: IBondHolderState;
  bonds: IBondState;
  bondOffer: IBondOfferState;
  transaction: IBondTransationState;
  certificates: ICertificateState;
  reports: IReports;
  fitReport: IFitReportingState;
  portfolio: IPortfolioState;
  otherConfig: IOtherConfigState;
  adminDashboardData: IAdminDashboardDataState;
  liveMeterDumps: ILiveMeterDumpsState;
  comments: IComments;
  costSummary: ICostSummary;
  generateReport: IGenerateReport;
  invoiceAccountReport: IInvoiceAccountReport;
  supporters: ISupporters;
  tsReadings: ITSReadingState;
  datafeed: IDatafeed
  aclAccess: IAclAccessState;
  aclPermission: IAclPermissionState
  aclRole: IAclRoleState
  spUser: ISPUserState
}

const admin = (
  state: IAdmin = {
    isLoading: false,
    token: localStorage.getItem("token"),
    isAuthenticated: false,
    authEvent: { login: false, logout: false },
    isLoadingData: false,
    redirectTo: "",
  },
  action: Action
): IAdmin => {
  switch (action.type) {
    case "SET_FETCHING":
      return { ...state, isLoading: action.isLoading };
    case "SET_USER_DETAILS":
      return {
        ...state,
        user: action.webUser,
        isAuthenticated: action.isValid,
      };
    case "SET_AUTH_TOKEN":
      return { ...state, isAuthenticated: true };
    case "REGISTER_AUTH_EVENT":
      return {
        ...state,
        authEvent: action.authEvent,
      };
    case "LOGOUT":
      if (state.isAuthenticated) {
        toast.success(`You have been logged out.`);
      }
      return { ...state, isAuthenticated: false, user: null, token: null };
    case "Redirect":
      return {
        ...state,
        redirectTo: action.to,
      };
    default:
      return state;
  }
};

export default combineReducers<State>({
  activityLogger,
  admin,
  team,
  report,
  translations,
  system,
  billing,
  invoice,
  invoiceReport,
  gssReport,
  school,
  product,
  opportunity,
  project,
  billingPeriod,
  task,
  projectWorkflow,
  taskType,
  userFunction,
  regionConfig,
  envConfig,
  userSkill,
  blogs,
  yields,
  meterReading,
  rtYields,
  user,
  faqs,
  bondHolder,
  bonds,
  bondOffer,
  transaction,
  certificates,
  reports,
  fitReport,
  portfolio,
  otherConfig,
  adminDashboardData,
  liveMeterDumps,
  comments,
  costSummary,
  generateReport,
  invoiceAccountReport,
  invoicePrice,
  supporters,
  tsReadings,
  datafeed,
  aclAccess,
  aclPermission,
  aclRole,
  spUser
});
