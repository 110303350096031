import axios from "axios";
import { ParsedUrlQueryInput } from "querystring";
import { generateQueryString } from "../../../utils/util";
import { solaportaApiHost } from "../../config";
import { IAclAccess } from "../../../components/admin/solarportal/aclAccesses/types";
import { getSolarPortalApiHeader } from "./helper";

export const getAclAccessesApi = (query: any): Promise<any> => {
	if (query.page && query.pageSize) {
		query.limit = query.pageSize;
		query.skip = (+query.page - 1) * (+query.pageSize);
	}
	if (query.sortField) {
		query.sortBy = `${query.sortField}`
	}
	if (query.sortOrder) {
		query.orderBy = `${query.sortOrder}`
	}
	return axios
		.get(
			`${solaportaApiHost}/v1/accesses?${generateQueryString(query as ParsedUrlQueryInput)}`, { headers: getSolarPortalApiHeader() }
		)
		.then((res) => res.data);
};


export const createAclAccessApi = (aclAccess: IAclAccess): Promise<any> => {
	return axios
		.post(`${solaportaApiHost}/v1/access`, aclAccess, { headers: getSolarPortalApiHeader() })
		.then((res) => res.data);
};

export const updateAclAccessApi = (aclAccess: IAclAccess): Promise<any> => {
	if (aclAccess.created) delete aclAccess.created;
	if (aclAccess.updated) delete aclAccess.updated;
	return axios
		.put(`${solaportaApiHost}/v1/access/${aclAccess._id}`, aclAccess, { headers: getSolarPortalApiHeader() })
		.then((res) => res.data);
};

export const deleteAclAccessApi = (aclAccess: IAclAccess): Promise<any> => {
	return axios
		.delete(`${solaportaApiHost}/v1/access/${aclAccess._id}`, { headers: getSolarPortalApiHeader() })
		.then((res) => res.data);
};
