import {
  IAwsS3KeyData,
  ICategoriesDatum,
  ICdnHostData,
  IGoogleDatum,
  ILanguageConfiguration,
  IReadingTypesDatum,
  ISenderBankAccData,
  ISitesDatum,
  ITemplatesDatum,
  IUtils1Data,
  IUtilsData,
  MsGraphData,
  TOtherConfigs,
} from "@solarforschools/sfs-core";
import { orderBy } from "lodash";

export const ConfigsDropDown = [
  { key: "ms-graph", text: "ms-graph" },
  { key: "aws-s3-keys", text: "aws-s3-keys" },
  { key: "templates", text: "templates" },
  { key: "sites", text: "sites" },
  { key: "categories", text: "categories" },
  { key: "blogcategories", text: "blogcategories" },
  { key: "language-list", text: "language-list" },
  { key: "countries", text: "countries" },
  { key: "funds", text: "funds" },
  { key: "funding-model", text: "funding-model" },
  { key: "adjustment-units", text: "adjustment-units" },
  { key: "partner-fee-units", text: "partner-fee-units" },
  { key: "contribution-units", text: "contribution-units" },
  { key: "reading-types", text: "reading-types" },
  { key: "confidence-levels", text: "confidence-levels" },
  { key: "utils", text: "utils" },
  { key: "sender-bank-account", text: "sender-bank-account" },
  { key: "cdn-host", text: "cdn-host" },
  { key: "project-stage-list", text: "project-stage-list" },
  { key: "google", text: "google" },
  { key: "utils1", text: "utils1" },
  { key: "services", text: "services" },
  { key: "services2", text: "services2" },
  { key: "status", text: "status" },
  { key: "map-markers", text: "Map Markers" },
];
export const sortedConfigsDropDown = orderBy(ConfigsDropDown, ["key"])

let responseDataType: IResponseDataType[] = [];

//Memoise the response data and its type , key as id and type as response.data's type
export function findResponseDataType(response: TOtherConfigs) {
  if (!response || !response?.data) return;

  const found = responseDataType.some((el) => el.id === response.id);
  if (!found) {
    if (typeof response.data === "object" && !Array.isArray(response.data)) {
      responseDataType = [
        ...responseDataType,
        { id: response.id, type: "Object" },
      ];
    } else if (Array.isArray(response.data)) {
      let arrLength = response.data.length;
      if (arrLength > 0) {
        if (
          typeof response.data[0] === "object" &&
          !Array.isArray(response.data[0])
        ) {
          responseDataType = [
            ...responseDataType,
            { id: response.id, type: "Array Of Object" },
          ];
        } else {
          responseDataType = [
            ...responseDataType,
            { id: response.id, type: "Array" },
          ];
        }
      }
    }
    return;
  }
}

//Fn convert object/array to array of objects (To render in table)
export const tableFormat = (response: TOtherConfigs) => {
  if (!response?.id) return response;
  const found = responseDataType.filter((el) => el.id === response.id);

  if (!found) return response;
  let modifiedData, result: IResultTableFormat;

  switch (found[0].type) {
    case "Object":
      modifiedData = ObjectToArrayOfObject(response.data);
      break;
    case "Array":
      modifiedData = ArrayToArrayOfObject(response.data as string[]);
      break;
    default:
      modifiedData = response.data;
      break;
  }

  result = { ...response, data: modifiedData as IArrayModified[] | IObjectModified[] | IArrOfObjectModified };

  return result;
};

//Fn convert back to db data's structure
export const responseFormat = (response: any): TOtherConfigs | undefined => {
  if (!response?.id) return;

  const found = responseDataType.filter((el) => el.id === response.id);
  if (!found) return;
  let modifiedData, result: TOtherConfigs;
  switch (found[0]?.type) {
    case "Object":
      modifiedData = objectConverter(response.data);
      break;
    case "Array":
      modifiedData = arrayConverter(response.data);
      break;
    default:
      modifiedData = response.data;
  }
  result = { ...response, data: modifiedData };
  return result;
};

//Fn - Array of Objects to array
const arrayConverter = (arrOfObj: Record<string, any>[]) => {
  if (Array.isArray(arrOfObj) && arrOfObj && !Array.isArray(arrOfObj[0])) {
    const resArr = arrOfObj.map((el) => el.value1).filter(Boolean);
    return resArr;
  }
};

//Fn - Array of Objects to Object
const objectConverter = (arrOfObj: Record<string, any>[]) => {
  if (Array.isArray(arrOfObj) && arrOfObj && !Array.isArray(arrOfObj[0])) {
    const obj = Object.assign(
      {},
      ...arrOfObj.map((el) => ({ [el.key]: el.value }))
    );

    const nonEmptykeys = Object.keys(obj).filter(Boolean);
    let resObj = {};
    for (let i = 0; i < nonEmptykeys.length; i++) {
      resObj = { ...resObj, [nonEmptykeys[i]]: obj[nonEmptykeys[i]] };
    }
    return resObj;
  }
};

//Fn - Object to Array of Objects
const ObjectToArrayOfObject = (obj: Record<string, any>) => {
  if (!Array.isArray(obj) && typeof obj === "object" && obj !== null) {
    const result = Object.keys(obj).map((key) => {
      return { key: key, value: obj[key] };
    });
    return result;
  }
};

//Fn -  Array to Array of objects
const ArrayToArrayOfObject = (arr: string[]) => {
  if (Array.isArray(arr)) {
    const result = arr.map((el: string) => {
      return { value1: el };
    });

    return result;
  }
};

export interface IObjectModified {
  key: string;
  value: any;
}

export interface IArrayModified {
  value1: string;
}

export type IArrOfObjectModified =
  | MsGraphData
  | IAwsS3KeyData
  | ITemplatesDatum[]
  | ISitesDatum[]
  | ICategoriesDatum[]
  | ILanguageConfiguration[]
  | string[]
  | IUtils1Data
  | IUtilsData
  | ISenderBankAccData
  | ICdnHostData
  | IReadingTypesDatum[]
  | IGoogleDatum;

export interface IResultTableFormat {
  data: IArrOfObjectModified | IArrayModified[] | IObjectModified[] | undefined;
  id: string;
  type: string;
}

export type ConvertedObjectType =
  | IArrOfObjectModified
  | IArrayModified[]
  | IObjectModified[]
  | undefined;

interface IResponseDataType {
  id: string;
  type: string;
}
