import axios from "axios";
import { ParsedUrlQueryInput } from "querystring";
import { generateQueryString } from "../../../utils/util";
import { solaportaApiHost } from "../../config";
import { IAclPermission } from "../../../components/admin/solarportal/aclPermissions/types";
// import { headers } from "./helper";
import { AxiosHeaders } from "axios";

export const getAclPermissionsApi = (query: any, headers:any): Promise<any> => {
	if (query.page && query.pageSize) {
		query.limit = query.pageSize;
		query.skip = (+query.page - 1) * (+query.pageSize);
	}
	if (query.sortField) {
		query.sortBy = `${query.sortField}`
	}
	if (query.sortOrder) {
		query.orderBy = `${query.sortOrder}`
	}
	return axios
		.get(
			`${solaportaApiHost}/v1/permissions?${generateQueryString(query as ParsedUrlQueryInput)}`, { headers }
		)
		.then((res) => res.data);
};


export const createAclPermissionApi = (aclPermission: IAclPermission ,headers: any): Promise<any> => {
	return axios
		.post(`${solaportaApiHost}/v1/permission`, aclPermission, { headers })
		.then((res) => res.data);
};

export const updateAclPermissionApi = (aclPermission: IAclPermission, headers:any): Promise<any> => {
	// if (aclPermission.created) delete aclPermission.created;
	// if (aclPermission.updated) delete aclPermission.updated;
	return axios
		.put(`${solaportaApiHost}/v1/permission/${aclPermission._id}`, aclPermission, { headers })
		.then((res) => res.data);
};

export const deleteAclPermissionApi = (aclPermission: IAclPermission, headers:any): Promise<any> => {
	return axios
		.delete(`${solaportaApiHost}/v1/permission/${aclPermission._id}`, { headers })
		.then((res) => res.data);
};
